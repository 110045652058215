import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PriorityButton } from "../common/Buttons"
import {
    faChartSimple,
    faCrystalBall,
    faChartPieSimple,
    faLightbulb,
    faSquareUser,
} from "@fortawesome/pro-regular-svg-icons"
import { CREATE_INSIGHT_PATH } from "../settings/insights/CreateInsights"
import { Link } from "react-router-dom"
import { Cell, Pie, PieChart } from "recharts"
import { LineChart, Line, XAxis, YAxis } from "recharts"
import { BarChart, Bar } from "recharts"

export function EmptyState() {
    return (
        <div className="flex flex-col gap-8 md:gap-20 p-4 md:p-0">
            <div className="flex flex-col items-center gap-4 text-center">
                <h1 className="text-3xl md:text-6xl">
                    Unlock insights from
                    <br /> the people you speak to
                </h1>
                <p className="text-gray-500 max-w-md">
                    Create quantitative insights from what prospects and
                    customers are saying
                </p>
                <div className="flex items-center gap-8">
                    <DummyLineChart />
                    <DummyPieChart />
                    <DummyBarChart />
                </div>
                <div className="my-6 md:my-12">
                    <Link to={CREATE_INSIGHT_PATH}>
                        <PriorityButton className="flex flex-row gap-2 items-center">
                            <FontAwesomeIcon icon={faLightbulb} />
                            Create a new insight
                        </PriorityButton>
                    </Link>
                </div>
            </div>

            <div className="flex flex-col items-center gap-8">
                <span className="text-center text-gray-500">
                    What you can learn
                </span>
                <div className="flex flex-wrap gap-4 md:flex-row md:gap-0">
                    <Item
                        icon={faChartSimple}
                        title="Frequency"
                        description="See how often an insight is mentioned by prospects, customers or reps"
                    />
                    <Divider />
                    <Item
                        icon={faCrystalBall}
                        title="Win / loss analysis"
                        description="Understand how an answer to an insight correlates with deal outcome"
                    />
                    <Divider />
                    <Item
                        icon={faSquareUser}
                        title="Identify ICP"
                        description="Learn what your most successful deals have in common"
                    />
                    <Divider />
                    <Item
                        icon={faChartPieSimple}
                        title="Loss reasons"
                        description="Use our AI to generate automatic loss reasons for all your deals"
                    />
                </div>
            </div>
        </div>
    )
}

function Divider() {
    return <div className="hidden md:block mx-8 w-px bg-gray-200" />
}

function Item(props: { icon: any; title: string; description: string }) {
    return (
        <div className="flex flex-col items-start space-y-2 w-full md:w-64">
            <div className="flex items-center gap-2">
                <div className="rounded-md bg-cyan-300 p-4 w-6 h-6 flex items-center justify-center">
                    <FontAwesomeIcon icon={props.icon} />
                </div>
                <h3 className="font-bold">{props.title}</h3>
            </div>
            <p className="text-gray-500">{props.description}</p>
        </div>
    )
}

function DummyPieChart() {
    const data = [
        { value: 25 },
        { value: 25 },
        { value: 15 },
        { value: 15 },
        { value: 15 },
    ]

    const COLORS = ["#E176CE", "#A082D5", "#8291D5", "#7FE2F3", "#7FF3B5"]
    return (
        <div className="relative">
            <PieChart width={50} height={50}>
                <Pie
                    isAnimationActive={false}
                    data={data}
                    cx={25}
                    cy={25}
                    innerRadius={15}
                    outerRadius={20}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                >
                    {data.map((_, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </div>
    )
}

function DummyLineChart() {
    const data = [
        { name: "Jan", Series1: 30, Series2: 20, Series3: 25 },
        { name: "Feb", Series1: 40, Series2: 25, Series3: 40 },
        { name: "Mar", Series1: 35, Series2: 30, Series3: 50 },
        { name: "Apr", Series1: 50, Series2: 45, Series3: 30 },
        { name: "May", Series1: 55, Series2: 50, Series3: 60 },
    ]

    return (
        <div className="relative">
            <LineChart
                width={60}
                height={60}
                data={data}
                margin={{
                    top: 25,
                }}
            >
                <XAxis dataKey="name" hide />
                <YAxis hide />
                <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    dataKey="Series1"
                    stroke="#7FF3B5"
                    strokeWidth={2}
                />
                <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    dataKey="Series2"
                    stroke="#A082D5"
                    strokeWidth={2}
                />
                <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    dataKey="Series3"
                    stroke="#E176CE"
                    strokeWidth={2}
                />
            </LineChart>
        </div>
    )
}

function DummyBarChart() {
    const data = [
        { name: "Category A", value: -40 },
        { name: "Category B", value: -30 },
        { name: "Category C", value: -20 },
        { name: "Category D", value: -10 },
    ]

    return (
        <div className="relative">
            <BarChart layout="vertical" width={50} height={50} data={data}>
                <XAxis type="number" hide />
                <YAxis type="category" dataKey="name" hide />
                <Bar
                    dataKey="value"
                    fill="#7FE2F3"
                    barSize={5}
                    isAnimationActive={false}
                />
            </BarChart>
        </div>
    )
}
