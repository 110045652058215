import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SecondaryButton } from "./Buttons"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link, To, useNavigate, useNavigationType } from "react-router-dom"

interface BackButtonProps {
    defaultCaption: string
    defaultTo: To
}

export function BackButton(props: BackButtonProps) {
    return (
        <NavigationButton
            {...props}
            showIcon={true}
            icon={faArrowLeft}
            captionOverride={"Back"}
        />
    )
}

interface CancelButtonProps {
    defaultTo: To
}

export function CancelButton(props: CancelButtonProps) {
    return (
        <NavigationButton
            {...props}
            showIcon={false}
            defaultCaption={"Cancel"}
            captionOverride={"Cancel"}
        />
    )
}

interface NavigationButtonProps {
    defaultCaption: string
    defaultTo: To
    captionOverride?: string
    showIcon?: boolean
    icon?: typeof faArrowLeft
}

function NavigationButton(props: NavigationButtonProps) {
    const navigate = useNavigate()
    const navigationType = useNavigationType()
    const hasPreviousPage =
        window.history.length > 1 && navigationType !== "POP"
    const caption = props.captionOverride || props.defaultCaption

    const content = (
        <>
            {props.showIcon && props.icon && (
                <FontAwesomeIcon className="h-4 w-4" icon={props.icon} />
            )}
            <div className="hidden md:block min-w-fit">{caption}</div>
        </>
    )

    if (hasPreviousPage) {
        return (
            <SecondaryButton
                className="flex flex-row gap-2 items-center"
                onClick={() => navigate(-1)}
            >
                {content}
            </SecondaryButton>
        )
    }

    return (
        <Link className="min-w-fit" to={props.defaultTo}>
            <SecondaryButton className="flex flex-row gap-2 items-center">
                {content}
            </SecondaryButton>
        </Link>
    )
}
