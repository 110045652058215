import React from "react"
import { SimpleCard } from "../common/SimpleCard"

interface CardProps {
    title: string
    // label can be a node to allow for formatted labels
    label?: string | React.ReactNode
    children: React.ReactNode
}

export function Card(props: CardProps) {
    return (
        <SimpleCard className="p-6">
            <div className="space-y-6 w-full">
                <div className="flex flex-col md:flex-row w-full justify-between items-center">
                    <div className="w-full flex flex-col">
                        <h2 className="text-xl font-bold">{props.title}</h2>
                        {props.label && (
                            <span className="text-gray-600 text-base">
                                {props.label}
                            </span>
                        )}
                    </div>
                </div>
                {props.children}
            </div>
        </SimpleCard>
    )
}
