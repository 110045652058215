import { useMemo, useState } from "react"
import _ from "lodash"

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { queries } from "../../../api/queries"
import { LoadingPulse } from "../../common/LoadingPulse"
import { ICrmField } from "../../crm/types/Crm"
import {
    faPencil,
    faCheckCircle,
    faEllipsisVertical,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CreateInsightButton, trackInsightEvent } from "./CreateInsights"
import { SimpleCard } from "../../common/SimpleCard"
import { Link } from "react-router-dom"
import { DEFAULT_GROUP_DISPLAY_NAME } from "./insightsCommon"
import { hasPermission } from "../../../utils/Permissions"
import { useUser } from "../../../providers/UserProvider"
import { Permission } from "../../../types/Permission"
import axios from "axios"
import { useNotification } from "../../../providers/NotificationProvider"
import { NotificationType } from "../../common/Notifcations"
import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { DangerousButton, SecondaryButton } from "../../common/Buttons"
import { Modal } from "../../common/Modal"

export function InsightsSettings(props: { orgName: string | undefined }) {
    const { data, isPending } = useQuery(queries.customInsights.list())
    const insights = useMemo(() => {
        return _.orderBy(
            data || [],
            [(item) => item.group_name ?? "", "display_name", "name"],
            ["asc", "asc", "asc"]
        ) as ICrmField[]
    }, [data])

    return (
        <>
            <div className="flex flex-col space-y-2 w-full">
                <h2 className="text-xl font-bold">What are Insights</h2>
                <span className="text-gray-600 text-base">
                    Insights are key points automatically captured from each
                    call to help you track the information that matters most to
                    your business.
                </span>
            </div>
            <SimpleCard className="p-6">
                <div className="space-y-6 w-full">
                    <div className="flex flex-col md:flex-row w-full justify-between items-center">
                        <div className="w-full flex flex-col">
                            <h2 className="text-xl font-bold">{`${props.orgName} Insights`}</h2>
                            {insights.length > 0 && (
                                <span className="text-gray-600 text-base">
                                    <span className="font-semibold text-lg">
                                        {insights.length}
                                    </span>{" "}
                                    organization-wide insights
                                </span>
                            )}
                        </div>
                        <CreateInsightButton />
                    </div>
                    {isPending ? (
                        <LoadingPulse rows={8} height="h-10" />
                    ) : insights?.length > 0 ? (
                        <InsightsList insights={insights} />
                    ) : (
                        <div>
                            <span className="text-gray-600 text-base">
                                You don't have any insights yet. Create one to
                                get started.
                            </span>
                        </div>
                    )}
                </div>
            </SimpleCard>
        </>
    )
}

function InsightsList(props: { insights: ICrmField[] }) {
    const user = useUser()
    const canEditCustomInsights =
        user && hasPermission(user, Permission.EDIT_CUSTOM_INSIGHTS)

    return (
        <div className="border border-gray-300 rounded-lg w-full">
            <div className="flex justify-between items-center p-4 italic md:hidden">
                Manage your insights on a desktop device.
            </div>
            <table className="w-full hidden md:table">
                <thead>
                    <tr className="h-16">
                        <th className="text-left px-4 py-2 w-48">Group</th>
                        <th className="text-left px-4 py-2 w-48">Name</th>
                        <th className="text-left px-4 py-2">
                            Prompt / question
                        </th>
                        <th className="text-center px-4 py-2 w-48">
                            Connected to CRM
                        </th>
                        {canEditCustomInsights && <th></th>}
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 border-t border-gray-300 text-sm">
                    {props.insights.map((insight) => (
                        <tr key={insight.name} className="hover:bg-gray-100">
                            <td className="px-4 py-2">
                                {insight.group_name ||
                                    DEFAULT_GROUP_DISPLAY_NAME}
                            </td>
                            <td className="px-4 py-2">
                                {insight.display_name || insight.name || ""}
                            </td>
                            <td className="px-4 py-2">
                                {insight.description || ""}
                            </td>
                            <td className="px-4 py-2 text-center">
                                {insight.crm_field_name ? (
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="text-green-500 w-4 h-4"
                                        data-tooltip-content={`${insight.crm_field_table}.${insight.crm_field_name}`}
                                        data-tooltip-id="tooltip-explanation"
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="text-gray-400 w-4 h-4"
                                    />
                                )}
                            </td>
                            {canEditCustomInsights && (
                                <td className="px-4 py-2">
                                    <OptionEllipsis insight={insight} />
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function OptionEllipsis(props: { insight: ICrmField }) {
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    return (
        <>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <button className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 focus:outline-none">
                        <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            className="text-gray-400 w-4 h-4"
                        />
                    </button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        className="bg-white rounded-md shadow-lg border border-gray-200 z-10"
                        sideOffset={2}
                        alignOffset={10}
                        align="end"
                    >
                        <DropdownMenu.Item className="text-sm text-gray-700 hover:bg-gray-100 focus:outline-none">
                            <Link
                                to={`/create-insight?insight=${props.insight.id}`}
                                className="px-4 py-2 flex items-center gap-2"
                            >
                                <FontAwesomeIcon
                                    icon={faPencil}
                                    className="w-4 h-4"
                                />
                                Edit
                            </Link>
                        </DropdownMenu.Item>

                        <DropdownMenu.Item className="text-sm text-gray-700 hover:bg-gray-100 focus:outline-none">
                            <button
                                className="px-4 py-2 flex items-center gap-2"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className="w-4 h-4"
                                />
                                Delete
                            </button>
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
            <DeleteConfirmationModal
                insight={props.insight}
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
            />
        </>
    )
}

function DeleteConfirmationModal(props: {
    insight: ICrmField
    isOpen: boolean
    onClose: () => void
}) {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const deleteInsight = useMutation({
        mutationFn: (customInsightId: string) =>
            axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/custom_insights/${customInsightId}`
            ),
        onSuccess: () => {
            queryClient.invalidateQueries(queries.customInsights.list())
            addNotification(
                `Insight "${props.insight.display_name}" was deleted!`,
                "",
                NotificationType.Success
            )
        },
        onError: (error) => {
            const errorMessage = axios.isAxiosError(error)
                ? error.response?.data["detail"]
                : error.message

            addNotification(
                "Failed to delete insight!",
                errorMessage,
                NotificationType.Error
            )
        },
    })

    return (
        <Modal isOpen={props.isOpen} onClose={() => props.onClose()}>
            <div className="flex flex-col w-full p-8 gap-4">
                <div className="font-bold text-lg mr-12">
                    Are you sure you want to delete insight "
                    {props.insight.display_name}"?
                </div>
                <div className="text-gray-600 text-sm">
                    This insight will no longer be captured in future calls but
                    will remain for calls analyzed while it was active.
                </div>
                <div className="flex justify-end">
                    <SecondaryButton
                        onClick={() => props.onClose()}
                        className="mr-4"
                    >
                        Cancel
                    </SecondaryButton>
                    <DangerousButton
                        onClick={() => {
                            props.onClose()
                            trackInsightEvent(
                                props.insight,
                                "custom-insight-deleted"
                            )
                            deleteInsight.mutate(props.insight.id)
                        }}
                    >
                        Delete
                    </DangerousButton>
                </div>
            </div>
        </Modal>
    )
}
