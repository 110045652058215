import { createQueryKeys } from "@lukemorales/query-key-factory"
import { getOrgSettings } from "../Settings"
import { OrgSettingsResponse } from "../../types/Settings"

export const organizationQueries = createQueryKeys("organization", {
    settings: () => ({
        queryKey: ["organization/settings"],
        queryFn: async () => {
            return (await getOrgSettings()) as OrgSettingsResponse
        },
    }),
})
