import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import {
    Granularity,
    InsightsResponse,
    ILossReasons,
} from "../../types/Insights"
import { DateTime } from "luxon"
import { transformSnakeKeysToCamelCase } from "../../utils/transformSnakeKeysToCamelCase"
import { DEFAULT_GROUP_NAME } from "../../components/CustomInsights"
import _ from "lodash"

export const insightQueries = createQueryKeys("insights", {
    get: (
        since: DateTime,
        granularity: Granularity,
        userIds: string[],
        minDealAmount: number | undefined,
        maxDealAmount: number | undefined
    ) => ({
        queryKey: [
            "insights",
            since,
            granularity,
            userIds,
            minDealAmount,
            maxDealAmount,
        ],
        queryFn: async () => {
            async function fetchInsights(
                since: DateTime,
                granularity: Granularity,
                userIds: string[]
            ): Promise<InsightsResponse> {
                const params = getSearchParams(
                    since.toISODate()!,
                    userIds,
                    minDealAmount,
                    maxDealAmount
                )
                params.append("granularity", granularity)

                const response = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/insights`,
                    {
                        params,
                    }
                )
                return response.data as InsightsResponse
            }

            function parseAndSortInsights(
                result: InsightsResponse
            ): InsightsResponse {
                result.insights = result.insights.map(
                    transformSnakeKeysToCamelCase
                )

                // Set default group name
                result.insights = result.insights.map((insight) => ({
                    ...insight,
                    groupName: insight.groupName || DEFAULT_GROUP_NAME,
                }))

                // Sort insights by group then display name
                result.insights = _.orderBy(
                    result.insights,
                    [
                        (insight) => insight.groupName!.toLowerCase(),
                        (insight) => insight.displayName.toLowerCase(),
                    ],
                    ["asc", "asc"]
                )

                return result
            }

            const result = await fetchInsights(since, granularity, userIds)
            return parseAndSortInsights(result)
        },
    }),

    getLossReasons: (
        since: DateTime,
        userIds: string[],
        minDealAmount: number | undefined,
        maxDealAmount: number | undefined
    ) => ({
        queryKey: [
            "insights",
            "loss-reasons",
            since,
            userIds,
            minDealAmount,
            maxDealAmount,
        ],
        queryFn: async () => {
            const params = getSearchParams(
                since.toISODate()!,
                userIds,
                minDealAmount,
                maxDealAmount
            )
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/insights/loss-reasons`,
                {
                    params,
                }
            )
            return response.data as ILossReasons
        },
    }),
})

function getSearchParams(
    since: string,
    userIds: string[],
    minDealAmount: number | undefined,
    maxDealAmount: number | undefined
): URLSearchParams {
    const params = new URLSearchParams({
        since: since,
    })
    if (userIds) {
        userIds.forEach((id) => params.append("user_ids", id))
    }
    if (minDealAmount) {
        params.append("min_deal_amount", minDealAmount.toString())
    }
    if (maxDealAmount) {
        params.append("max_deal_amount", maxDealAmount.toString())
    }
    return params
}
