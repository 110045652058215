export enum CrmType {
    Hubspot = "hubspot",
    Salesforce = "salesforce",
    Pipedrive = "pipedrive-oauth",
}

export enum CrmTable {
    Deals = "deals",
    Users = "users",
    Contacts = "contacts",
    Companies = "companies",
    Notes = "notes",
}

export enum CrmFlow {
    CreateDealNote = "create-deal-note",
    CreateContactNote = "create-contact-note",
}

export interface ICrmBase {
    id: string
    name: string | null | undefined
}

export interface IIntegrationAppCrmUser extends ICrmBase {
    fields: any
    unifiedFields: Partial<{
        firstName: string
        lastName: string
        fullName: string
        primaryEmail: string
        primaryPhone: string
        companyName: string
        companyId: string
        jobTitle: string
    }>
    // NOTE: This is potentially an incomplete list of fields. Add more as needed.
}

export interface ICrmDeal extends ICrmBase {
    uri: string
    fields: any
    unifiedFields: Partial<{
        stage: string
        companyId: string
    }>
    // NOTE: This is an incomplete list of fields. Add more as needed.
}

export interface ICrmContact extends IIntegrationAppCrmUser {}
export interface ICrmCompany extends IIntegrationAppCrmUser {}

export interface ICrmNote extends ICrmBase {
    fields: any
    unifiedFields: Partial<{
        dealId: string
        companyId: string
        content: string
    }>
}

export interface IChecklistOption {
    id: string
    name: string
}

export interface IAutoGeneratedOptions {
    options: IChecklistOption[]
}

export enum ICrmFieldType {
    Checklist = "checklist",
    MultiChecklist = "multi-checklist",
    Text = "string",
    Number = "number",
}

export interface ICrmField {
    id: string
    name: string
    display_name?: string
    description?: string
    crm_field_table?: string
    crm_field_name?: string
    crm_field_type?: ICrmFieldType
    group_name?: string
    options?: IChecklistOption[]
    auto_generated_options?: IAutoGeneratedOptions
}

export interface ICrmUser {
    crm_id: string
    name: string
    email: string
}

export interface ICrmDealStage {
    crm_id: string
    name: string
    order: number
}

export interface ICrmDealPipeline {
    crm_id: string
    name: string
    order: number
    stages: ICrmDealStage[]
}

export interface ICrmObjectReference {
    name: string
    email: string | null
    crm_object_id: string
    crm_table: CrmTable
    crm_object_url: string
    has_synced: boolean
}

export interface IInferredCrmFieldValue {
    field_name: string
    field_display_name: string | null
    value: string | null
    display_value?: string | null
    value_additional_context?: string | null
    group_name?: string | null
}

export interface IResolvedCrmFieldValue extends IInferredCrmFieldValue {
    existing_crm_value?: string
    source_table: CrmTable
    source_id: string | null // null means we don't have a matching CRM object
    crm_field_name: string
}
