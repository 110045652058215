import { ICrmFieldType } from "../components/crm/types/Crm"
import { CrmFieldSchema } from "../types/Settings"

export function getFieldType(
    crmTypeInfo: CrmFieldSchema
): ICrmFieldType | null {
    const fieldTypeInfo = crmTypeInfo.type

    if (!fieldTypeInfo) {
        console.error("Type not found in CRM info: ", crmTypeInfo)
        return null
    }

    switch (fieldTypeInfo) {
        case "number":
            return ICrmFieldType.Number

        case "string":
            if ("referenceRecords" in crmTypeInfo) {
                return ICrmFieldType.Checklist
            }
            if (crmTypeInfo.format === "date-time") {
                // For now we only return supported types
                return null
            }
            return ICrmFieldType.Text

        case "array":
            if (crmTypeInfo.items?.referenceRecords) {
                return ICrmFieldType.MultiChecklist
            }
            return null

        default:
            return null
    }
}
