import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICrmField } from "../../components/crm/types/Crm"
import { CrmFieldsSchemaResponse } from "../../types/Settings"

export const customInsightsQueries = createQueryKeys("customInsights", {
    list: () => ({
        queryKey: ["custom_insights"],
        queryFn: async () => {
            const customInsightsUrl = `${process.env.REACT_APP_API_DOMAIN}/custom_insights`
            const response = await axios.get(customInsightsUrl)
            return response.data as ICrmField[]
        },
    }),

    crmSchema: () => ({
        queryKey: ["custom_insights/crm/schema"],
        queryFn: async () => {
            const customInsightsUrl = `${process.env.REACT_APP_API_DOMAIN}/custom_insights/crm/schema`
            const response = await axios.get(customInsightsUrl)
            return response.data as CrmFieldsSchemaResponse
        },
    }),
})
