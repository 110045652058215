import { mergeQueryKeys } from "@lukemorales/query-key-factory"
import { callQueries } from "./calls"
import { upcomingCallQueries } from "./upcomingCalls"
import { userQueries } from "./users"
import { crmQueries } from "./crm"
import { customInsightsQueries } from "./customInsights"
import { organizationQueries } from "./organization"
import { callTagQueries } from "./callTags"
import { versionQueries } from "./version"
import { insightQueries } from "./insights"
import { announcementQueries } from "./announcements"
import { dealsQueries } from "./deals"
import { sharedCallQueries } from "./sharedCall"
import { companyQueries } from "./companies"

export const queries = mergeQueryKeys(
    organizationQueries,
    callQueries,
    sharedCallQueries,
    callTagQueries,
    upcomingCallQueries,
    userQueries,
    crmQueries,
    customInsightsQueries,
    versionQueries,
    announcementQueries,
    insightQueries,
    companyQueries,
    dealsQueries
)
