import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { useUser } from "../../providers/UserProvider"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { CopyButton } from "../common/CopyButton"
import {
    IInferredCrmFieldValue,
    IResolvedCrmFieldValue,
} from "../crm/types/Crm"
import { getFormattedSectionNotes } from "../crm/utils/getFormattedNotes"
import { FieldList, getDisplayName } from "../CustomInsights"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons"
import { Link } from "react-router-dom"
import { CREATE_INSIGHT_PATH } from "../settings/insights/CreateInsights"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"
import { FrigadeCreateCustomInsightTour } from "../Frigade"
import { showKnowledgeBaseArticle } from "../../utils/pylon"
import { KnowledgeBaseArticleSlugs } from "../../utils/pylon"
import { queries } from "../../api/queries"
import { useQuery } from "@tanstack/react-query"
import { LoadingPulse } from "../common/LoadingPulse"

interface CustomInsightsProps {
    callId: string
    callTitle: string
    inferredValues: IInferredCrmFieldValue[]
}

export function CustomInsights({
    callId,
    callTitle,
    inferredValues,
}: CustomInsightsProps) {
    const user = useUser()
    const canEditCustomInsights =
        user && hasPermission(user, Permission.EDIT_CUSTOM_INSIGHTS)
    const hasCrmAccess: boolean = !!user // CRM access only available to logged in users
    const [mappedFields, setMappedFields] = useState<IResolvedCrmFieldValue[]>(
        []
    )
    const [showSyncUI, setShowSyncUI] = useState(false)
    const [disableSyncReason, setDisableSyncReason] = useState<string | null>(
        null
    )

    useEffect(() => {
        async function getMappedFields() {
            try {
                setMappedFields([])
                setDisableSyncReason("Loading...")
                // First update sync all companies with the latest CRM info to ensure we have the latest CRM IDs for them
                await axios.patch(
                    `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/companies/refresh_crm_info`
                )
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/crm/fields/`
                )
                const fields = data as IResolvedCrmFieldValue[]
                setMappedFields(fields)
                const fieldsWithCrmObject = fields.filter(
                    (field) => field.source_id
                )

                if (data.length === 0) {
                    setDisableSyncReason(
                        "Insights have not been linked to CRM fields. Ask an admin to link them in the insights settings."
                    )
                } else if (fieldsWithCrmObject.length === 0) {
                    setDisableSyncReason(
                        "This call could not be matched to any CRM object."
                    )
                } else {
                    setDisableSyncReason(null) // Enable sync button
                }
            } catch (error) {
                setDisableSyncReason(getDisableSyncReason(error))
                console.error("Failed to get mapped fields", error)
            }
        }
        if (hasCrmAccess) {
            getMappedFields()
        }
    }, [callId, hasCrmAccess])

    const fields: IInferredCrmFieldValue[] = useMemo(() => {
        if (inferredValues.length === 0) return inferredValues

        const fieldsToDisplay = showSyncUI ? mappedFields : inferredValues
        return fieldsToDisplay.sort(
            (a, b) =>
                getDisplayName(a).localeCompare(getDisplayName(b), undefined, {
                    numeric: true,
                }) || 0
        )
    }, [showSyncUI, mappedFields, inferredValues])

    if (fields.length === 0) {
        return <EmptyState canEditCustomInsights={!!canEditCustomInsights} />
    }

    const ViewSyncButton = () =>
        showSyncUI ? (
            <PrimaryButton onClick={() => setShowSyncUI(false)}>
                Close
            </PrimaryButton>
        ) : (
            <SecondaryButton
                onClick={() => setShowSyncUI(true)}
                disabled={disableSyncReason !== null}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={disableSyncReason}
            >
                Sync to CRM ...
            </SecondaryButton>
        )

    return (
        <div className="bg-white border rounded-lg p-3 text-base space-y-4">
            <div className="flex justify-between w-full">
                <h2 className="text-xl font-bold mr-3">Custom Insights</h2>
                <div className="flex justify-end space-x-8 items-center">
                    {!showSyncUI && (
                        <>
                            <CopyButton
                                content={getFormattedNotes(callTitle, fields)}
                            />
                            {canEditCustomInsights ? (
                                <Link to={CREATE_INSIGHT_PATH}>
                                    <FontAwesomeIcon
                                        id="frigade-create-custom-insight"
                                        icon={faCirclePlus}
                                        className="w-5 h-5 text-gray-600 hover:text-gray-800"
                                        data-tooltip-id="tooltip-explanation"
                                        data-tooltip-content="Create a new insight"
                                    />
                                </Link>
                            ) : (
                                <FontAwesomeIcon
                                    icon={faCirclePlus}
                                    className="w-5 h-5 opacity-20"
                                    data-tooltip-id="tooltip-explanation"
                                    data-tooltip-content="You need to be an admin to create a new insight"
                                />
                            )}
                            <FrigadeCreateCustomInsightTour />
                        </>
                    )}
                    {hasCrmAccess && <ViewSyncButton />}
                </div>
            </div>
            <FieldList
                fields={fields}
                showSyncUI={showSyncUI}
                callId={callId}
            />
        </div>
    )
}

function getDisableSyncReason(error: any) {
    if (axios.isAxiosError(error)) {
        if (error.response?.status === 412) {
            return "No CRM linked"
        } else if (error.response?.status === 424) {
            // We do not expect to be displaying the component in this case
            return "No custom insights found"
        }
    }
    return "Failed to get CRM mapped fields"
}

function getFormattedNotes(
    callTitle: string,
    inferredValues: IInferredCrmFieldValue[]
) {
    const fieldsString = [
        "Custom Insights:",
        ...inferredValues.map((field) => {
            const displayValue =
                field.display_value || field.value || "Not covered"
            return `- ${
                field.field_display_name || field.field_name
            }: ${displayValue}`
        }),
    ].join("\n")
    return getFormattedSectionNotes(callTitle, fieldsString)
}

function EmptyState(props: { canEditCustomInsights: boolean }) {
    const { data: insights, isPending } = useQuery(
        queries.customInsights.list()
    )
    const noInsightsConfigured = insights?.length === 0

    if (isPending) {
        return <LoadingPulse rows={1} height="h-10" />
    }
    if (noInsightsConfigured) {
        return (
            <div className="bg-white border text-gray-600 rounded-lg p-6 flex flex-col items-center text-center space-y-4">
                <div>
                    No insights have been configured for your Glyphic account.
                </div>
                <div>
                    Insights are key points automatically captured from each of
                    your calls to help you track the information that matters
                    most to your business.
                </div>
                <div>
                    They can be synced to your CRM, and analysed across all of
                    your conversations.
                </div>
                <span
                    className="text-blue-500 underline cursor-pointer"
                    onClick={() =>
                        showKnowledgeBaseArticle(
                            KnowledgeBaseArticleSlugs.CUSTOM_INSIGHTS_PROMPTS
                        )
                    }
                >
                    View examples of what insights can capture
                </span>
                {props.canEditCustomInsights ? (
                    <Link to={CREATE_INSIGHT_PATH}>
                        <PrimaryButton>Create an insight</PrimaryButton>
                    </Link>
                ) : (
                    <div>
                        Ask an admin to set up insights for your organization.
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <div className="bg-white border text-gray-600 rounded-lg p-6 flex flex-col items-center text-center space-y-4">
                <div>No insights were recorded for this call.</div>
            </div>
        )
    }
}
