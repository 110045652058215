import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColoredLabelSquare } from "../common/ColoredLabelSquare"
import { SelectionState } from "./SelectionState"
import clsx from "clsx"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { SecondaryButton } from "../../common/Buttons"
import { useNavigate } from "react-router-dom"
import { parseInsightToUrl } from "../../calls-list/CallsList"
import { InsightResult } from "../../../types/Insights"

export function Legend(props: {
    insight: InsightResult
    colors: string[]
    totalCounts: [string, number][]
    selectionState: SelectionState
    toggleSelectedOption: (option: string) => void
    setHoveredOption: (option: string | null) => void
    isOptionSelected: (option: string, state: SelectionState) => boolean
}) {
    const navigate = useNavigate()
    const insightName = props.insight.displayName
    return (
        <div className="flex flex-col w-full text-sm">
            <div className="flex flex-row justify-between w-full">
                <span className="font-bold mb-3 text-gray-800">
                    {insightName}
                </span>
                <span className="font-bold mb-3 pr-8 text-gray-800">Total</span>
            </div>
            {props.totalCounts.map(([option, total], index) => (
                <div
                    key={option}
                    className="flex flex-row justify-between w-full gap-2 group items-center"
                    onMouseEnter={() => props.setHoveredOption(option)}
                    onMouseLeave={() => props.setHoveredOption(null)}
                >
                    <button
                        className={clsx(
                            "flex items-center gap-2 text-gray-600 hover:font-semibold overflow-hidden w-full",
                            !props.isOptionSelected(
                                option,
                                props.selectionState
                            ) && "opacity-50 hover:opacity-100"
                        )}
                        onClick={() => props.toggleSelectedOption(option)}
                    >
                        <ColoredLabelSquare
                            color={props.colors[index % props.colors.length]}
                            // We use the props.selectionState.selected
                            // directly so that we don't count with the
                            // hovered state.
                            isSelected={props.selectionState.selected.has(
                                option
                            )}
                        />
                        <span
                            className="flex-1 text-left truncate"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content={option}
                        >
                            {option}
                        </span>
                    </button>
                    <div
                        className={clsx(
                            "w-fit min-w-0",
                            !props.isOptionSelected(
                                option,
                                props.selectionState
                            ) && "opacity-50"
                        )}
                    >
                        <div className="whitespace-nowrap">
                            <span className="font-bold text-gray-600">
                                {total}
                            </span>
                        </div>
                    </div>
                    <SecondaryButton
                        className="opacity-0 transition-opacity group-hover:opacity-100 p-0"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-html={`<div style="text-align: center;">View calls where <b>${insightName}</b> is<br/><b>${option}</b></div>`}
                        onClick={() => {
                            navigate(
                                `/calls?insight=${parseInsightToUrl({
                                    insightName: props.insight.name,
                                    values: [option],
                                })}`
                            )
                        }}
                    >
                        <FontAwesomeIcon
                            className="transition-colors hover:text-gray-500"
                            icon={faMagnifyingGlass}
                        />
                    </SecondaryButton>
                </div>
            ))}
        </div>
    )
}
