import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { LineChart } from "./line-chart/LineChart"
import {
    Granularity,
    InsightCategoryType,
    InsightResult,
    InsightsResponse,
} from "../../types/Insights"
import { DateTime } from "luxon"
import { useMemo, useState } from "react"
import Select from "react-select"
import colors from "tailwindcss/colors"
import { BetaLabel } from "../common/BetaLabel"
import { DealOutcomesChart } from "./DealOutcomes"
import { MultiSelectFilter } from "../MultiSelectFilter"
import { IOrganizationUser } from "../../types/Organization"
import { LossReasons } from "./LossReasons"
import { submitPylonFeedback } from "../../utils/pylon"
import { LoadingPulse } from "../common/LoadingPulse"
import { Contents } from "./Contents"
import {
    LosingFormulaChart,
    WinningFormulaChart,
} from "./summary-charts/DealPatternsCharts"
import { MostMentionsChart } from "./summary-charts/MostMentionsChart"
import { SimpleCard } from "../common/SimpleCard"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons"
import { CREATE_INSIGHT_PATH } from "../settings/insights/CreateInsights"
import { Link } from "react-router-dom"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"
import { useUser } from "../../providers/UserProvider"
import { EmptyState } from "./EmptyState"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { faChartScatter, faSparkles } from "@fortawesome/pro-solid-svg-icons"
import { ICrmFieldType } from "../crm/types/Crm"
import clsx from "clsx"

type DealAmountRange = {
    min?: number
    max?: number
}

export function StrategicInsights() {
    const user = useUser()
    const canEditCustomInsights =
        user && hasPermission(user, Permission.EDIT_CUSTOM_INSIGHTS)

    const [sinceDate, setSinceDate] = useState<DateTime>(
        DateTime.now().minus({ months: 12 }).startOf("day")
    )
    const [granularity, setGranularity] = useState<Granularity>(
        Granularity.MONTHLY
    )

    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
    const [dealAmountRange, setDealAmountRange] = useState<DealAmountRange>({})

    const { data: users, isPending: isUsersPending } = useQuery(
        queries.users.list()
    )

    const insightsQuery = useQuery({
        ...queries.insights.get(
            sinceDate,
            granularity,
            selectedUserIds,
            dealAmountRange.min,
            dealAmountRange.max
        ),
        staleTime: Infinity,
    })

    const lossReasonsQuery = useQuery({
        ...queries.insights.getLossReasons(
            sinceDate,
            selectedUserIds,
            dealAmountRange.min,
            dealAmountRange.max
        ),
        staleTime: Infinity,
    })

    const isEmpty =
        !insightsQuery.isPending &&
        !insightsQuery.data?.insights.length &&
        !lossReasonsQuery.isPending &&
        !lossReasonsQuery.data?.loss_reasons.length

    if (isEmpty)
        return (
            <div className="flex h-full justify-center items-center">
                <EmptyState />
            </div>
        )

    return (
        <section className="rounded-lg px-8 py-6 space-y-4 flex flex-col lg:h-[calc(100vh)] overflow-hidden">
            <div className="flex flex-col flex-shrink-0 justify-between gap-2">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-2">
                        <h1 className="text-2xl font-bold">
                            Strategic Insights
                        </h1>
                        <BetaLabel />
                        <BetaNotice />
                    </div>
                    {canEditCustomInsights ? (
                        <Link to={CREATE_INSIGHT_PATH}>
                            <SecondaryButton className="flex gap-2 items-center">
                                <FontAwesomeIcon icon={faPlusCircle} />
                                Create new insight
                            </SecondaryButton>
                        </Link>
                    ) : (
                        <SecondaryButton
                            className="flex gap-2 items-center"
                            disabled
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content="You need to be an admin to create a new insight"
                        >
                            <FontAwesomeIcon icon={faPlusCircle} />
                            Create new insight
                        </SecondaryButton>
                    )}
                </div>
                <p className="text-gray-500">
                    Strategic Insights help you identify trends and
                    opportunities from your{" "}
                    <span className="font-semibold">Glyphic call data</span>.
                </p>
                <div className="flex items-center gap-2 mt-4 space-x-1">
                    <span className="text-gray-900 font-semibold">Date</span>
                    <DateSelect
                        sinceDate={sinceDate}
                        setSinceDate={setSinceDate}
                        setGranularity={setGranularity}
                    />
                    <span className="text-gray-900 font-semibold pl-4">
                        Deal Amount
                    </span>
                    <DealFilter
                        amountRange={dealAmountRange}
                        setAmountRange={setDealAmountRange}
                    />
                    <span className="text-gray-900 font-semibold pl-4">
                        People
                    </span>
                    <PeopleSelect
                        users={users}
                        selectedUserIds={selectedUserIds}
                        setSelectedUserIds={setSelectedUserIds}
                        isLoading={isUsersPending}
                    />
                </div>
            </div>
            <div className="lg:grid grid-cols-4 gap-4 overflow-hidden">
                <div className="col-span-1 mb-4 lg:mb-0 overflow-y-auto">
                    <Contents
                        lossReasonsQueryResult={lossReasonsQuery}
                        insightsQueryResult={insightsQuery}
                    />
                </div>
                <div className="col-span-3 space-y-4 overflow-y-scroll scroll-smooth">
                    <LossReasons lossReasonsQueryResult={lossReasonsQuery} />
                    <MostMentionsChart insightsQueryResult={insightsQuery} />
                    <WinningFormulaChart insightsQueryResult={insightsQuery} />
                    <LosingFormulaChart insightsQueryResult={insightsQuery} />
                    <InsightsCharts
                        insightsQueryResult={insightsQuery}
                        sinceDate={insightsQuery.data?.since}
                        granularity={granularity}
                        userIds={selectedUserIds}
                    />
                </div>
            </div>
        </section>
    )
}

function InsightsCharts(props: {
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
    sinceDate: string | undefined
    granularity: Granularity
    userIds: string[]
}) {
    const result = props.insightsQueryResult
    if (result.isPending)
        return (
            <div>
                <LoadingPulse rows={2} height="h-80" />
            </div>
        )

    if (
        result.isError ||
        !result.data?.insights ||
        result.data?.insights.length === 0
    ) {
        return <Empty />
    }

    return (
        <div className="space-y-4">
            {result.data.insights.map((insight, index) => (
                <Insight
                    key={index}
                    insight={insight}
                    sinceDate={props.sinceDate!}
                    userIds={props.userIds}
                />
            ))}
        </div>
    )
}

function Empty() {
    return (
        <div className="flex items-center justify-center w-full h-64">
            <span className="bg-gray-200 p-2 rounded-lg text-gray-800">
                No insights to display
            </span>
        </div>
    )
}

function Insight(props: {
    insight: InsightResult
    sinceDate: string
    userIds: string[]
}) {
    const noData = !props.insight.lineChart && !props.insight.dealOutcomes
    return (
        <SimpleCard>
            <div
                className={clsx(
                    "flex flex-col p-6 w-full rounded-lg",
                    noData && "bg-gray-100 text-gray-500"
                )}
                id={props.insight.id} // Used by <Contents /> for navigation
            >
                <h2 className="text-2xl font-bold mb-3">
                    {props.insight.displayName}
                    {props.insight.insightCategoryType ===
                        InsightCategoryType.AUTO_GENERATED && (
                        <AutoGeneratedCategoriesIcon
                            fieldType={props.insight.fieldType}
                        />
                    )}
                </h2>

                <div className="space-y-4">
                    {noData && <NoDataMessage insight={props.insight} />}
                    {props.insight.lineChart && (
                        <LineChart
                            insight={props.insight}
                            data={props.insight.lineChart.data}
                            granularity={props.insight.lineChart.granularity}
                        />
                    )}
                    {props.insight.dealOutcomes && (
                        <DealOutcomesChart
                            insightId={props.insight.id}
                            insightName={props.insight.displayName}
                            data={props.insight.dealOutcomes.data}
                            sinceDate={props.sinceDate}
                            userIds={props.userIds}
                        />
                    )}
                </div>
            </div>
        </SimpleCard>
    )
}

function NoDataMessage(props: { insight: InsightResult }) {
    const icon =
        props.insight.insightCategoryType ===
        InsightCategoryType.AUTO_GENERATION_PENDING
            ? faSparkles
            : faChartScatter

    const message =
        props.insight.insightCategoryType ===
        InsightCategoryType.AUTO_GENERATION_PENDING
            ? `Once this ${getFieldTypeDisplayName(
                  props.insight.fieldType
              )} insight has been mentioned on enough calls, groups will be automatically generated to display this chart.`
            : "No mentions of this insight within the selected filters."

    return (
        <div className="flex items-center gap-2 text-gray-500">
            <FontAwesomeIcon
                icon={icon}
                className="rounded-lg bg-gray-100 w-4 h-4 p-2"
            />
            <p>{message}</p>
        </div>
    )
}

function AutoGeneratedCategoriesIcon(props: { fieldType: ICrmFieldType }) {
    return (
        <span
            className="inline-flex items-center justify-center h-6 p-2 ml-2 rounded-full
                       bg-gradient-to-br from-yellow-300 to-orange-400
                     text-gray-900 text-sm font-normal space-x-1 group cursor-default"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-html={`Your <b>${getFieldTypeDisplayName(
                props.fieldType
            )}</b> answers for this insight have been grouped using Glyphic's AI in this chart.`}
        >
            <FontAwesomeIcon
                icon={faSparkles}
                className="transition-transform duration-200 group-hover:scale-125 group-hover:rotate-12"
            />
            <span className="hidden md:inline">Grouped using AI</span>
        </span>
    )
}

function getFieldTypeDisplayName(fieldType: ICrmFieldType) {
    return {
        [ICrmFieldType.Text]: "free text",
        [ICrmFieldType.Number]: "numeric",
        [ICrmFieldType.Checklist]: "checklist",
        [ICrmFieldType.MultiChecklist]: "multi select",
    }[fieldType]
}

function BetaNotice() {
    return (
        <p className="text-sm text-gray-500 bg-yellow-50 border-2 border-yellow-200 p-2 rounded-lg w-fit">
            This is a beta feature in active development. If you have any
            questions or feedback{" "}
            <button
                className="text-blue-500 underline"
                onClick={async () =>
                    await submitPylonFeedback("Strategic Insights Feedback")
                }
            >
                click here to let us know
            </button>
            .
        </p>
    )
}

function DateSelect(props: {
    sinceDate: DateTime
    setSinceDate: (date: DateTime) => void
    setGranularity: (granularity: Granularity) => void
}) {
    const dateOptions = useMemo(
        () => [
            {
                label: "Last 30 days",
                value: DateTime.now().minus({ days: 30 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 3 months",
                value: DateTime.now().minus({ months: 3 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 6 months",
                value: DateTime.now().minus({ months: 6 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 9 months",
                value: DateTime.now().minus({ months: 9 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 12 months",
                value: DateTime.now().minus({ months: 12 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 18 months",
                value: DateTime.now().minus({ months: 18 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
        ],
        []
    )

    const currentOption = useMemo(() => {
        return (
            dateOptions.find(
                (option) =>
                    option.value?.toMillis() === props.sinceDate?.toMillis()
            ) || null
        )
    }, [props.sinceDate, dateOptions])

    return (
        <Select
            options={dateOptions}
            className="w-56"
            styles={{
                control: (provided) => ({
                    ...provided,
                    borderColor: colors.gray[200],
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                    "&:hover": {
                        borderColor: colors.gray[300],
                        backgroundColor: colors.gray[50],
                    },
                }),
                menu: (provided: any) => ({
                    ...provided,
                    padding: "0px 4px 0px 4px",
                    borderRadius: "0.5rem",
                    zIndex: 50,
                }),
                option: (provided: any) => ({
                    ...provided,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "0.5rem",
                    "&:hover": {
                        backgroundColor: colors.gray[100],
                    },
                }),
            }}
            onChange={(option) => {
                if (!option) return
                props.setSinceDate(option.value)
                props.setGranularity(option.granularity)
            }}
            value={currentOption}
            isSearchable={false}
        />
    )
}

function PeopleSelect(props: {
    users?: IOrganizationUser[]
    selectedUserIds: string[]
    setSelectedUserIds: (userIds: string[]) => void
    isLoading: boolean
}) {
    const userToOption = (user: IOrganizationUser) => ({
        value: user.id,
        label: user.name || user.id,
    })

    const options = useMemo(() => {
        return (
            props.users
                ?.map(userToOption)
                .sort((a, b) => a.label.localeCompare(b.label)) || []
        )
    }, [props.users])

    const value = useMemo(() => {
        return (
            props.users
                ?.filter((user) => props.selectedUserIds.includes(user.id))
                .map(userToOption) || []
        )
    }, [props.selectedUserIds, props.users])

    return (
        <MultiSelectFilter
            title="people"
            icon="👤"
            options={options}
            value={value}
            onApply={(selectedOption) => {
                props.setSelectedUserIds(
                    selectedOption.map((option) => option.value)
                )
            }}
            isLoading={props.isLoading}
        />
    )
}

function DealFilter(props: {
    amountRange: DealAmountRange
    setAmountRange: (range: DealAmountRange) => void
}) {
    const { addNotification } = useNotification()
    const [open, setOpen] = useState(false)
    const [amountRange, setAmountRange] = useState<DealAmountRange>(
        props.amountRange
    )

    const numberFormat = new Intl.NumberFormat()

    const format = (value: number | undefined) =>
        value !== undefined ? numberFormat.format(value) : ""

    const getDisplayValue = ({ min, max }: DealAmountRange) => {
        if (min && max) {
            return `${format(min)} - ${format(max)}`
        }

        if (min) {
            return `> ${format(min)}`
        }

        if (max) {
            return `< ${format(max)}`
        }

        return "Any"
    }

    const handleInputChange =
        (field: keyof DealAmountRange) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const parsedInt = parseInt(
                event.target.value.replace(/[^0-9]/g, "")
            )
            const amount = isNaN(parsedInt) ? undefined : parsedInt
            setAmountRange((prev) => ({ ...prev, [field]: amount }))
        }

    const handleApply = (amountRange: DealAmountRange) => {
        const { min, max } = amountRange
        if (min !== undefined && max !== undefined && min > max) {
            addNotification(
                "Min amount must be less than Max amount",
                "",
                NotificationType.Error
            )
            return
        }
        props.setAmountRange(amountRange)
        setOpen(false)
    }

    return (
        <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownMenuTrigger asChild>
                <button className="w-64 bg-white rounded-md p-2 border flex items-center justify-between hover:bg-gray-50 cursor-default focus:outline-none">
                    <span>{getDisplayValue(props.amountRange)}</span>
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className="h-3 w-3 opacity-50"
                    />
                </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                className="bg-white rounded-md shadow-lg border border-gray-200 z-10"
                align="start"
                sideOffset={5}
                alignOffset={-5}
            >
                <div className="space-y-4 p-3">
                    <div className="flex gap-4">
                        <div className="flex-1">
                            <label className="text-sm font-medium mb-1 block">
                                Min Amount
                            </label>
                            <input
                                type="text"
                                placeholder="None"
                                value={format(amountRange.min)}
                                onChange={handleInputChange("min")}
                                className="border rounded w-full py-2 px-3"
                            />
                        </div>
                        <div className="flex-1">
                            <label className="text-sm font-medium mb-1 block">
                                Max Amount
                            </label>
                            <input
                                type="text"
                                placeholder="None"
                                value={format(amountRange.max)}
                                onChange={handleInputChange("max")}
                                className="border rounded w-full py-2 px-3"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end pt-2 border-t">
                        <PrimaryButton onClick={() => handleApply(amountRange)}>
                            Apply
                        </PrimaryButton>
                    </div>
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
