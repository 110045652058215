import { PieChart, Pie, ResponsiveContainer, Cell, Sector } from "recharts"
import { useId, useMemo, useState } from "react"
import clsx from "clsx"
import { ColoredLabelSquare } from "./common/ColoredLabelSquare"
import { getColorTable } from "./utils/colors"
import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { LossReason } from "../../types/Insights"
import { ILossReasons } from "../../types/Insights"
import { LoadingPulse } from "../common/LoadingPulse"
import { queries } from "../../api/queries"
import { DealsTooltip } from "./common/DealsTooltip"
import { IStrategicInsightNavigationItem } from "./utils/navigation"
import { SimpleCard } from "../common/SimpleCard"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartPie } from "@fortawesome/pro-regular-svg-icons"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"

const COLORS = getColorTable("500")
const COLORS_LIGHT = getColorTable("300")

export const LOSS_REASONS_NAV_ITEM: IStrategicInsightNavigationItem = {
    id: "loss-reasons",
    displayName: "Deal Loss Reasons",
    isSummary: true,
}

export function LossReasons(props: {
    lossReasonsQueryResult: UseQueryResult<ILossReasons, Error>
}) {
    const result = props.lossReasonsQueryResult
    const [selectedHighLevelReasonIndex, setSelectedHighLevelReasonIndex] =
        useState<number | undefined>(undefined)
    const [highlightedReasonIndex, setHighlightedReasonIndex] = useState<
        number | undefined
    >()

    const highlightedLossReason = useMemo(() => {
        if (highlightedReasonIndex === undefined) return undefined
        if (selectedHighLevelReasonIndex === undefined) {
            return result.data?.loss_reasons[highlightedReasonIndex]
        }
        const activeSubReason =
            result.data?.loss_reasons[selectedHighLevelReasonIndex]
                .subcategories![highlightedReasonIndex]
        return activeSubReason
    }, [highlightedReasonIndex, result.data, selectedHighLevelReasonIndex])

    if (result.isPending) return <LoadingPulse rows={2} height="h-96" />

    if (!canShowLossReasons(result.data)) {
        return null
    }

    const isShowingSubReason = selectedHighLevelReasonIndex !== undefined

    const data = isShowingSubReason
        ? result.data.loss_reasons[selectedHighLevelReasonIndex].subcategories!
        : result.data.loss_reasons

    const legendTitle = isShowingSubReason
        ? "Subcategories"
        : "High-level reasons"

    const chartDealCount = isShowingSubReason
        ? result.data.loss_reasons[selectedHighLevelReasonIndex].count
        : result.data.total_lost_deals

    const colors = isShowingSubReason ? COLORS_LIGHT : COLORS

    return (
        <SimpleCard>
            <div
                className="flex flex-col p-6 w-full"
                id={LOSS_REASONS_NAV_ITEM.id}
            >
                <h2 className="text-2xl font-bold mb-3 text-gray-800">
                    {LOSS_REASONS_NAV_ITEM.displayName}
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full bg-gray-50 rounded-lg p-4">
                    <div className="w-full">
                        {isShowingSubReason && (
                            <div className="mb-4">
                                <div className="flex items-center gap-2">
                                    <SecondaryButton
                                        className="flex items-center gap-2"
                                        onClick={() =>
                                            setSelectedHighLevelReasonIndex(
                                                undefined
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </SecondaryButton>
                                    <span className="font-bold">
                                        {
                                            result.data.loss_reasons[
                                                selectedHighLevelReasonIndex
                                            ].loss_category
                                        }
                                    </span>
                                </div>
                            </div>
                        )}
                        <Legend
                            title={legendTitle}
                            data={data}
                            colors={colors}
                            totalDealCount={chartDealCount}
                            activeIndex={highlightedReasonIndex}
                            setActiveIndex={setHighlightedReasonIndex}
                            selectHighLevelReason={
                                setSelectedHighLevelReasonIndex
                            }
                        />
                    </div>
                    <div className="flex flex-col h-full justify-center">
                        <div className="h-64">
                            <LossReasonsChart
                                data={data}
                                dealCount={
                                    highlightedLossReason?.count ??
                                    chartDealCount
                                }
                                activeIndex={highlightedReasonIndex}
                                setActiveIndex={setHighlightedReasonIndex}
                                colors={colors}
                            />
                        </div>
                        <Description lossReason={highlightedLossReason} />
                    </div>
                </div>
            </div>
        </SimpleCard>
    )
}

function Description(props: { lossReason: LossReason | undefined }) {
    return (
        <div className="h-16 items-center hidden md:flex">
            {props.lossReason && (
                <div className="w-full bg-gray-900 p-2 rounded-lg text-center">
                    <span className="text-sm text-gray-50 line-clamp-3 p-1">
                        <b>{props.lossReason.loss_category}</b>
                        {props.lossReason.description && (
                            <>
                                {": "}
                                {props.lossReason.description}
                            </>
                        )}
                    </span>
                </div>
            )}
        </div>
    )
}

export function canShowLossReasons(
    data: ILossReasons | undefined
): data is ILossReasons {
    return data !== undefined && data.total_lost_deals > 0
}

function LossReasonsChart(props: {
    data: LossReason[]
    dealCount: number
    activeIndex: number | undefined
    setActiveIndex: (index: number | undefined) => void
    colors: string[]
}) {
    function onPieEnter(_: any, index: number) {
        props.setActiveIndex(index)
    }

    return (
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    data={props.data}
                    dataKey="count"
                    nameKey="loss_category"
                    startAngle={90}
                    endAngle={450}
                    innerRadius={"70%"}
                    outerRadius={"90%"}
                    activeShape={renderActiveShape}
                    activeIndex={props.activeIndex}
                    onMouseEnter={onPieEnter}
                    onMouseLeave={() => props.setActiveIndex(undefined)}
                >
                    {props.data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={props.colors[index % props.colors.length]}
                            opacity={
                                props.activeIndex !== undefined &&
                                props.activeIndex !== index
                                    ? 0.5
                                    : 1
                            }
                        />
                    ))}
                </Pie>
                <text
                    x="50%"
                    y="50%"
                    dy={-4}
                    textAnchor="middle"
                    className="text-3xl font-bold"
                >
                    {props.dealCount}
                </text>
                <text x="50%" y="50%" dy={16} textAnchor="middle">
                    Lost Deals
                </text>
            </PieChart>
        </ResponsiveContainer>
    )
}

function renderActiveShape(props: any) {
    // Copied from https://recharts.org/en-US/examples/CustomActiveShapePieChart
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
        props
    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    )
}

function Legend(props: {
    title: string
    data: LossReason[]
    colors: string[]
    totalDealCount: number
    activeIndex: number | undefined
    setActiveIndex: (index: number | undefined) => void
    selectHighLevelReason: (index: number | undefined) => void
}) {
    return (
        <table className="w-full table-fixed">
            <thead className="text-sm">
                <tr>
                    <th className="text-left px-4 py-2">{props.title}</th>
                    <th className="text-left px-8 w-16 whitespace-nowrap overflow-visible">
                        Total Deals
                    </th>
                    <th className="w-16"></th>
                    <th className="w-16"></th>
                </tr>
            </thead>
            <tbody className="divide-y divide-transparent">
                {props.data.map((lossReason, index) => (
                    <LegendEntry
                        lossReason={lossReason}
                        index={index}
                        color={props.colors[index % props.colors.length]}
                        totalDealCount={props.totalDealCount}
                        isActive={props.activeIndex === index}
                        isInactive={
                            props.activeIndex !== undefined &&
                            props.activeIndex !== index
                        }
                        setActiveIndex={props.setActiveIndex}
                        onClick={
                            lossReason.subcategories?.length
                                ? () => props.selectHighLevelReason(index)
                                : undefined
                        }
                    />
                ))}
            </tbody>
        </table>
    )
}

function LegendEntry(props: {
    color: string
    lossReason: LossReason
    index: number
    totalDealCount: number
    isActive: boolean
    isInactive: boolean
    setActiveIndex: (index: number | undefined) => void
    onClick?: () => void
}) {
    const percentage = Math.round(
        (props.lossReason.count / props.totalDealCount) * 100
    )
    const tooltipId = useId()

    const deal_ids = props.lossReason.deal_ids
    const { data: deals, isPending: dealsIsPending } = useQuery({
        ...queries.deals.getByIds(deal_ids),
        enabled: props.isActive && !!deal_ids,
        staleTime: Infinity,
    })

    return (
        <tr
            className={clsx(
                "h-12 items-center transition-opacity text-sm",
                props.isInactive && "opacity-50",
                props.onClick && "cursor-pointer"
            )}
            onMouseEnter={() => props.setActiveIndex(props.index)}
            onMouseLeave={() => props.setActiveIndex(undefined)}
            onClick={props.onClick}
        >
            <td className="px-4" data-tooltip-id={tooltipId}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 w-full">
                        <ColoredLabelSquare
                            key={props.lossReason.loss_category}
                            color={props.color}
                            isSelected={false}
                        />
                        <span
                            className={clsx(
                                "truncate",
                                props.isActive && "font-bold"
                            )}
                        >
                            {props.lossReason.loss_category}
                        </span>
                    </div>
                </div>
                <DealsTooltip
                    tooltipId={tooltipId}
                    deals={deals ?? []}
                    pendingRowCount={dealsIsPending ? deal_ids.length : 0}
                    place="left"
                    isOpen={props.isActive}
                />
            </td>
            <td className="px-4 text-right">{props.lossReason.count}</td>
            <td className="px-4 font-bold text-right">{percentage}%</td>
            <td className="px-4">
                {props.onClick && (
                    <SecondaryButton
                        onClick={props.onClick}
                        className="whitespace-nowrap"
                        data-tooltip-content="View breakdown"
                        data-tooltip-id="tooltip-explanation"
                    >
                        <FontAwesomeIcon icon={faChartPie} />
                    </SecondaryButton>
                )}
            </td>
        </tr>
    )
}
