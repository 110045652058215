import { faFlask } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { queries } from "../../../api/queries"
import { useUser } from "../../../providers/UserProvider"
import { BackButton, CancelButton } from "../../common/NavigationButtons"
import { PrimaryButton } from "../../common/Buttons"
import LoadingSpinner from "../../common/LoadingSpinner"
import { InsightGroupSelect } from "./InsightGroupSelect"
import { ICustomInsightTestRun } from "../../../types/CustomInsights"
import { InsightTestResults } from "./InsightTestResults"
import { transformSnakeKeysToCamelCase } from "../../../utils/transformSnakeKeysToCamelCase"
import { EditableCrmField } from "./insightsCommon"
import { useSearchParam } from "../../common/hooks/useSearchParam"
import axios from "axios"
import { useNotification } from "../../../providers/NotificationProvider"
import { NotificationType } from "../../common/Notifcations"
import clsx from "clsx"
import { StepBubble } from "./StepBubble"
import { OutputConfigurationSection } from "./OutputConfigurationSection"
import { hasPermission } from "../../../utils/Permissions"
import { Permission } from "../../../types/Permission"
import { IChecklistOption, ICrmFieldType } from "../../crm/types/Crm"
import { getHeapInstance } from "../../../utils/heap"
import {
    KnowledgeBaseArticleSlugs,
    showKnowledgeBaseArticle,
} from "../../../utils/pylon"
import { Tabs as SettingsTabs } from "../Settings"

export const CREATE_INSIGHT_PATH = "/create-insight"
const MAX_INSIGHTS = 50

export function CreateInsightButton() {
    const user = useUser()
    const canEditCustomInsights =
        user && hasPermission(user, Permission.EDIT_CUSTOM_INSIGHTS)

    return (
        <PrimaryButton
            className="whitespace-nowrap"
            disabled={!canEditCustomInsights}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                canEditCustomInsights
                    ? undefined
                    : "You need to be an admin to create a new insight"
            }
        >
            {canEditCustomInsights ? (
                <Link to={CREATE_INSIGHT_PATH}>Create Insight</Link>
            ) : (
                "Create Insight"
            )}
        </PrimaryButton>
    )
}

export function CreateInsightPage() {
    const { data: allInsights, isPending: isPendingGroups } = useQuery(
        queries.customInsights.list()
    )

    const [insightId] = useSearchParam("insight")
    const [editableInsight, setEditableInsight] = useState<EditableCrmField>({
        name: "",
        display_name: "",
        description: "", // prompt
        group_name: "",
        crm_field_table: undefined,
        crm_field_name: undefined,
    })

    useEffect(() => {
        if (allInsights && insightId && editableInsight.id !== insightId) {
            const insight = allInsights.find(
                (insight) => insight.id === insightId
            )
            if (insight) {
                setEditableInsight({ ...insight })
            }
        }
    }, [allInsights, insightId, editableInsight.id])

    const existingGroups = useMemo(() => {
        return _(allInsights ?? [])
            .map((insight) => insight.group_name)
            .compact()
            .uniq()
            .sort()
            .value()
    }, [allInsights])

    if (isPendingGroups) {
        return <LoadingSpinner />
    }

    return (
        <CreateInsightView
            insightsCount={allInsights?.length || 0}
            insight={editableInsight}
            groups={existingGroups}
            setInsight={setEditableInsight}
        />
    )
}

function CreateInsightView(props: {
    insightsCount: number
    insight: EditableCrmField
    groups: string[]
    setInsight: (insight: EditableCrmField) => void
}) {
    const { addNotification } = useNotification()
    const [showTestResults, setShowTestResults] = useState(false)
    const isCreatingNewInsight = !props.insight.id

    const generateTests = useMutation({
        mutationFn: async (insight: EditableCrmField) => {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/custom_insights/tests`,
                insight
            )
            return response.data.map(
                transformSnakeKeysToCamelCase
            ) as ICustomInsightTestRun[]
        },
        onError: () => {
            setShowTestResults(false)
            addNotification(
                "Failed to generate example results.",
                "Please try again.",
                NotificationType.Error
            )
        },
    })

    const handleTestInsight = useCallback(() => {
        setShowTestResults(true)
        generateTests.mutate(props.insight)
    }, [props.insight, generateTests, setShowTestResults])

    return (
        <div className="flex flex-col h-screen bg-white">
            <div className="flex flex-row justify-between items-center px-6 py-2 border-b space-x-4 bg-white">
                <BackButton defaultCaption="Settings" defaultTo="/settings" />
            </div>
            <div className="flex-1 min-h-0">
                <div
                    className={clsx(
                        "h-full grid",
                        showTestResults
                            ? "lg:grid-cols-2"
                            : "lg:grid-cols-3 max-w-3xl mx-auto"
                    )}
                >
                    <div
                        className={clsx(
                            "h-full overflow-y-auto bg-white",
                            showTestResults
                                ? "col-span-1 scrollbar-hide"
                                : "col-span-3"
                        )}
                    >
                        <div className="p-4 md:p-10 space-y-10">
                            {isCreatingNewInsight &&
                            props.insightsCount >= MAX_INSIGHTS ? (
                                <InsightLimitReached
                                    insightsCount={props.insightsCount}
                                />
                            ) : (
                                <CreateInsightForm
                                    insight={props.insight}
                                    setInsight={props.setInsight}
                                    groups={props.groups}
                                />
                            )}
                        </div>
                    </div>
                    {showTestResults && (
                        <div
                            className="h-full bg-gray-50 p-20 overflow-y-auto col-span-1 scrollbar-hide
                                       border-t lg:border-t-0 lg:border-l border-gray-200"
                        >
                            <InsightTestResults
                                results={generateTests.data}
                                isLoading={generateTests.isPending}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-white p-4 border-t w-full">
                <FooterNavBar
                    insight={props.insight}
                    setInsight={props.setInsight}
                    onTestInsight={handleTestInsight}
                    generationInProgress={generateTests.isPending}
                />
            </div>
        </div>
    )
}

function InsightLimitReached(props: { insightsCount: number }) {
    return (
        <div className="bg-gray-50 p-6 rounded-lg space-y-4">
            <h3 className="text-lg font-semibold">Maximum Insights Reached</h3>
            <p className="text-sm text-gray-600">
                You currently have {props.insightsCount} insights configured,
                which is the maximum allowed. If you need to create more
                insights, please reach out to our team at{" "}
                <a
                    href="mailto:support@glyphic.ai"
                    className="text-blue-600 hover:underline"
                >
                    support@glyphic.ai
                </a>
                .
            </p>
        </div>
    )
}

function CreateInsightForm({
    insight,
    setInsight,
    groups,
}: {
    insight: EditableCrmField
    setInsight: (insight: EditableCrmField) => void
    groups: string[]
}) {
    const handleOutputCrmFieldSelect = useCallback(
        (table: string | undefined, field: string | undefined) => {
            setInsight((prevInsight: EditableCrmField) => ({
                ...prevInsight,
                crm_field_table: table,
                crm_field_name: field,
                crm_field_type: undefined,
            }))
        },
        [setInsight]
    )

    const handleOutputTypeSelect = useCallback(
        (fieldType: ICrmFieldType | undefined) => {
            setInsight((prevInsight: EditableCrmField) => ({
                ...prevInsight,
                crm_field_type: fieldType,
            }))
        },
        [setInsight]
    )

    const handleChecklistOptionsChange = useCallback(
        (options: IChecklistOption[]) => {
            setInsight((prevInsight: EditableCrmField) => ({
                ...prevInsight,
                options,
            }))
        },
        [setInsight]
    )

    return (
        <div className="flex flex-col gap-12">
            <h1 className="flex py-6 text-3xl font-bold">Create insight</h1>

            <InsightDefinitionSection
                insight={insight}
                setInsight={setInsight}
            />

            <OptionalSettingsSection
                insight={insight}
                setInsight={setInsight}
                groups={groups}
            />

            <OutputConfigurationSection
                selectedTable={insight.crm_field_table}
                selectedField={insight.crm_field_name}
                selectedFieldType={insight.crm_field_type}
                checklistOptions={insight.options}
                onCrmFieldSelect={handleOutputCrmFieldSelect}
                onTypeSelect={handleOutputTypeSelect}
                onChecklistOptionsChange={handleChecklistOptionsChange}
            />
        </div>
    )
}

function InsightDefinitionSection(props: {
    insight: EditableCrmField
    setInsight: (insight: EditableCrmField) => void
}) {
    return (
        <section className="space-y-4">
            <div className="flex items-center gap-3">
                <StepBubble step={1} />
                <h1 className="text-2xl font-bold">
                    What do you want to extract from each call?
                </h1>
            </div>

            <div className="space-y-5">
                <h2 className="text-lg font-bold">Insight name</h2>
                <span className="text-sm text-gray-500">
                    This will be the name displayed in the UI
                </span>
                <input
                    placeholder="E.g. Competitors mentioned"
                    className="w-full h-10 border rounded-lg p-2 placeholder:italic placeholder:text-sm"
                    value={props.insight.display_name}
                    onChange={(e) =>
                        props.setInsight({
                            ...props.insight,
                            name: _.snakeCase(e.target.value),
                            display_name: e.target.value,
                        })
                    }
                />
            </div>

            <div className="space-y-5">
                <div className="flex flex-col gap-2">
                    <h2 className="text-lg font-bold">Prompt / question</h2>
                    <span className="text-sm text-gray-500">
                        These are the instructions that our AI will use to
                        extract the insight from the call.{" "}
                        <span
                            className="text-blue-600 underline cursor-pointer"
                            onClick={() =>
                                showKnowledgeBaseArticle(
                                    KnowledgeBaseArticleSlugs.CUSTOM_INSIGHTS_PROMPTS
                                )
                            }
                        >
                            View example prompts
                        </span>
                    </span>
                </div>
            </div>
            <div className="space-y-5">
                <div className="grid grid-cols-3 gap-2 h-full">
                    <HintCard
                        emoji="🧑"
                        title="Hint 1"
                        description="Write your prompt in plain English, as if you were asking another person"
                    />
                    <HintCard
                        emoji="📚"
                        title="Hint 2"
                        description="Insights work best when they are specific and focused"
                    />
                    <HintCard
                        emoji="🐝"
                        title="Hint 3"
                        description='Refine and iterate the prompt using "Test&nbsp;Insight"'
                    />
                </div>
            </div>
            <PromptTextArea
                prompt={props.insight.description}
                setPrompt={(prompt) =>
                    props.setInsight({
                        ...props.insight,
                        description: prompt,
                    })
                }
            />
        </section>
    )
}

function OptionalSettingsSection(props: {
    insight: EditableCrmField
    setInsight: (insight: EditableCrmField) => void
    groups: string[]
}) {
    return (
        <section className="space-y-4">
            <div className="flex items-center gap-3">
                <StepBubble step={2} />
                <h1 className="text-2xl font-bold">
                    Settings <span className="font-normal">(optional)</span>
                </h1>
            </div>

            <h2 className="text-lg font-bold">Group name</h2>
            <span className="text-sm text-gray-500">
                Select a group to organize your insights
            </span>
            <InsightGroupSelect
                groups={props.groups}
                group={props.insight.group_name}
                setGroup={(group) =>
                    props.setInsight({
                        ...props.insight,
                        group_name: group,
                    })
                }
            />
        </section>
    )
}

function HintCard(props: {
    emoji: string
    title: string
    description: string
}) {
    return (
        <div className="bg-gray-50 p-2 px-6 h-full flex flex-col rounded-lg">
            <div className="flex flex-col items-center gap-2">
                <div className="flex flex-row items-center gap-2 w-full relative">
                    <span className="text-sm absolute left-0">
                        {props.emoji}
                    </span>
                    <span className="text-sm text-center w-full font-semibold">
                        {props.title}
                    </span>
                </div>
                <span className="text-sm text-left w-full">
                    {props.description}
                </span>
            </div>
        </div>
    )
}

function PromptTextArea(props: {
    prompt: string | undefined
    setPrompt: (prompt: string) => void
}) {
    return (
        <div className="p-[1px] rounded-lg bg-gradient-to-r from-cyan-300 to-blue-500">
            <div className="w-full rounded-lg bg-white">
                <textarea
                    className="w-full h-40 min-h-fit rounded-lg p-2 bg-white outline-none resize-none placeholder:italic placeholder:text-sm"
                    placeholder="E.g. What competitors did the prospect mention in this call?"
                    value={props.prompt}
                    onChange={(e) => props.setPrompt(e.target.value)}
                ></textarea>
            </div>
        </div>
    )
}

const useCreateInsight = () => {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async (insight: EditableCrmField) => {
            const url = `${process.env.REACT_APP_API_DOMAIN}/custom_insights`
            const insightData = _.omit(insight, "id")

            if (insight.id) {
                await axios.patch(`${url}/${insight.id}`, insightData)
            } else {
                await axios.post(url, insightData)
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.customInsights.list().queryKey,
            })
            addNotification(
                "Success",
                "Insight saved successfully",
                NotificationType.Success
            )
            navigate("/settings", {
                state: { tab: SettingsTabs.Insights },
            })
        },
        onError: (error) => {
            addNotification(
                "Failed to save insight",
                `${error}`,
                NotificationType.Error
            )
        },
    })
}

function FooterNavBar(props: {
    insight: EditableCrmField
    setInsight: (insight: EditableCrmField) => void
    onTestInsight: () => void
    generationInProgress: boolean
}) {
    const fieldsFilled = requiredFieldsFilled(props.insight)
    const createInsight = useCreateInsight()
    const [testClicked, setTestClicked] = useState(false)

    // We must do explicitly because null defaults to true
    const isNewInsight = !props.insight.id
    const createButtonCaption = isNewInsight ? "Create" : "Update"

    const handleCreate = () => {
        trackInsightEvent(
            props.insight,
            isNewInsight ? "custom-insight-created" : "custom-insight-updated"
        )
        createInsight.mutate(props.insight)
    }

    const handleTest = () => {
        setTestClicked(true)
        trackInsightEvent(props.insight, "custom-insight-test-triggered")
        props.onTestInsight()
    }

    let disableTestReason = null
    if (props.generationInProgress) {
        disableTestReason = "Generating example results..."
    } else if (!fieldsFilled) {
        disableTestReason = "Please fill out all fields"
    }

    let disableCreateReason = disableTestReason
    if (!disableTestReason && !testClicked && isNewInsight) {
        disableCreateReason = "Please test the insight before creating"
    }

    return (
        <div className="flex justify-end space-x-4">
            <CancelButton defaultTo="/settings" />
            <PrimaryButton
                onClick={handleTest}
                className="flex items-center gap-2"
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={disableTestReason}
                disabled={!!disableTestReason}
            >
                <FontAwesomeIcon icon={faFlask} />
                Test Insight
            </PrimaryButton>
            <PrimaryButton
                onClick={handleCreate}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={disableCreateReason}
                disabled={!!disableCreateReason}
            >
                {createButtonCaption}
            </PrimaryButton>
        </div>
    )
}

function requiredFieldsFilled(insight: EditableCrmField): boolean {
    if (
        !(
            (insight.crm_field_table && insight.crm_field_name) ||
            insight.crm_field_type
        )
    ) {
        return false
    }

    if (
        insight.crm_field_type === ICrmFieldType.Checklist ||
        insight.crm_field_type === ICrmFieldType.MultiChecklist
    ) {
        const hasValidChecklistOptions =
            insight.options?.length &&
            insight.options.every((option) => option.id)

        if (!hasValidChecklistOptions) return false
    }
    return !!(insight.name && insight.display_name && insight.description)
}

export function trackInsightEvent(insight: EditableCrmField, event: string) {
    getHeapInstance()?.track(event, {
        insightId: insight.id,
        insightName: insight.name,
        insightDescription: insight.description,
        insightType: insight.crm_field_type,
    })
}
