import { useState } from "react"
import { INewsArticle } from "../../types/CompanyProfile"
import { getSimpleDate } from "../../utils/datetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"

const NUM_NEWS_TO_SHOW_COLLAPSED = 3

interface CompanyNewsProps {
    news: INewsArticle[] | null
    isLoading?: boolean
}

export function CompanyNews(props: CompanyNewsProps) {
    const [isCollapsed, setIsCollapsed] = useState(true)

    if (props.isLoading) {
        return <LoadingNews />
    } else if (!props.news || props.news.length === 0) {
        return <MissingNews />
    }

    const newsToDisplay = isCollapsed
        ? props.news.slice(0, NUM_NEWS_TO_SHOW_COLLAPSED)
        : props.news
    const isCollapsable = props.news.length > NUM_NEWS_TO_SHOW_COLLAPSED

    return (
        <div className="flex flex-col gap-4 overflow-x-auto">
            <span className="p-1 text-gray-900 text-base font-semibold leading-normal">
                News
            </span>
            <div className="space-y-6 pb-1">
                {newsToDisplay.map((article) => (
                    <NewsArticle key={article.url} article={article} />
                ))}
            </div>
            {isCollapsable && (
                <button
                    className="p-1 place-self-end text-gray-800 text-sm font-semibold leading-tight tracking-tight outline-none"
                    onClick={() => setIsCollapsed((prev) => !prev)}
                >
                    {isCollapsed ? "View more stories" : "View fewer stories"}{" "}
                    {isCollapsed ? (
                        <FontAwesomeIcon icon={faChevronDown} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronUp} />
                    )}
                </button>
            )}
        </div>
    )
}

function LoadingNews() {
    return (
        <div className="p-1 flex flex-col gap-2 w-full items-start">
            <span className="text-gray-900 text-base font-semibold leading-normal">
                News
            </span>
            <span className="text-gray-800 text-xs font-light leading-tight animate-pulse">
                Loading content
            </span>
        </div>
    )
}

function MissingNews() {
    return (
        <div className="items-start w-full p-1 flex flex-col gap-2">
            <span className="text-gray-900 text-base font-semibold leading-normal">
                News
            </span>
            <span className="text-gray-800 text-xs font-light leading-tight">
                No news to show
            </span>
        </div>
    )
}

function NewsArticle(props: { article: INewsArticle }) {
    const article = props.article
    return (
        <a
            className="flex flex-col p-1 rounded-lg hover:bg-gray-50 hover:delay-150 items-baseline space-y-2"
            href={article.url}
            target="_blank"
            rel="noreferrer"
        >
            <span className="text-gray-800 text-xs font-light leading-tight">
                {article.publisher}
            </span>
            <span className="text-gray-800 text-base font-bold leading-tight">
                {article.title}
            </span>
            {article.posted_on && (
                <span className="text-gray-800 text-xs font-light leading-tight">
                    {getSimpleDate(article.posted_on)}
                </span>
            )}
        </a>
    )
}
