import clsx from "clsx"

interface SimpleCardProps {
    className?: string
    children?: React.ReactNode
    id?: string
}

export function SimpleCard(props: SimpleCardProps) {
    const classNames = clsx(
        "bg-white flex md:flex-row flex-col rounded-lg shadow-md shadow-gray-200 items-center w-full",
        props.className
    )
    return (
        <div className={classNames} id={props.id}>
            {props.children}
        </div>
    )
}
