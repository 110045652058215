import { IUser } from "../../../types/User"

export function substitutePromptTemplates(
    message: string,
    user: IUser | undefined
): string {
    // Substitute template variables used in default Quick Actions
    // Supported variables: {{userName}}, {{organizationName}}
    if (!user) return message
    message = message.replace(/{{userName}}/g, user.name)
    message = message.replace(/{{organizationName}}/g, user.organizationName)
    return message
}
