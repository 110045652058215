import { Card } from "./Card"
import {
    AccessLevel,
    SavedQuickAction,
} from "../question-answering/types/QuickActionTypes"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBookmark,
    faEarthAmericas,
    faLandmark,
    faPencil,
    faTrashCan,
    faUser,
    faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { RecordingIcon } from "../../assets/icons/RecordingIcon"
import { getSimpleDate } from "../../utils/datetime"
import { useUser } from "../../providers/UserProvider"
import {
    EditQuickActionModal,
    NewQuickActionModal,
} from "../question-answering/QuickActionModals"
import { useMemo, useState } from "react"
import {
    QuickActionsApiHook,
    useQuickActionsApi,
} from "../question-answering/hooks/useQuickActionsApi"
import { IUser } from "../../types/User"
import clsx from "clsx"
import { hasEditPermission } from "../question-answering/utils/quickActionPermissions"
import { ResourceType } from "../question-answering/types/ResourceTypes"
import { QuickActionDeleteModal } from "../question-answering/QuickActionDeleteModal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { QUESTION_SUGGESTIONS } from "../question-answering/question-suggestions"
import Fuse from "fuse.js"

export function QuickActionLibrary() {
    const {
        quickActions,
        editQuickAction,
        deleteQuickAction,
        createQuickAction,
    } = useQuickActionsApi()
    return (
        <>
            <div className="flex flex-col space-y-2 w-full">
                <h2 className="text-xl font-bold">What are Quick Actions</h2>
                <span className="text-gray-600 text-base">
                    Quick Actions are pre-defined AI prompts that you can reuse
                    across any Ask Glyphic conversation at the click of a
                    button.
                    <br />
                    They can either be personal to your own account or shared
                    across your team or whole organization.
                </span>
            </div>
            <Card
                title="Your Quick Actions"
                label="View and manage your personal and organization-wide Ask Glyphic Quick Actions."
            >
                <NewQuickAction createQuickAction={createQuickAction} />
                <QuickActionTable
                    quickActions={quickActions}
                    editQuickAction={editQuickAction}
                    deleteQuickAction={deleteQuickAction}
                />
            </Card>
            <Card
                title="Suggested Quick Actions"
                label="These are Quick Actions recommended by Glyphic that you can add to your library."
            >
                <SuggestedQuickActionsTable
                    quickActions={quickActions}
                    createQuickAction={createQuickAction}
                />
            </Card>
        </>
    )
}

function NewQuickAction(props: {
    createQuickAction: QuickActionsApiHook["createQuickAction"]
}) {
    const [selectedResource, setSelectedResource] = useState<ResourceType>(
        ResourceType.Calls
    )
    const [showNewQuickActionModal, setShowNewQuickActionModal] =
        useState(false)
    return (
        <div className="flex flex-row items-center gap-2 h-10">
            <span className="text-gray-600">
                Create a new Quick Action to ask over:
            </span>
            <select
                className="border border-gray-300 rounded-lg p-2 h-full focus:outline-none"
                onChange={(e) =>
                    setSelectedResource(e.target.value as ResourceType)
                }
            >
                <option value={ResourceType.Calls}>Calls</option>
                <option value={ResourceType.Companies}>Companies</option>
            </select>
            <PrimaryButton
                className="h-full"
                onClick={() => setShowNewQuickActionModal(true)}
            >
                Create Quick Action
            </PrimaryButton>
            {showNewQuickActionModal && (
                <NewQuickActionModal
                    threadId={null}
                    resourceType={selectedResource}
                    isOpen={showNewQuickActionModal}
                    onClose={() => setShowNewQuickActionModal(false)}
                    saveQuickAction={async (title, accessLevel, question) => {
                        await props.createQuickAction(
                            title,
                            accessLevel,
                            question,
                            selectedResource
                        )
                        setShowNewQuickActionModal(false)
                    }}
                />
            )}
        </div>
    )
}

function QuickActionTable(props: {
    quickActions: QuickActionsApiHook["quickActions"]
    editQuickAction: QuickActionsApiHook["editQuickAction"]
    deleteQuickAction: QuickActionsApiHook["deleteQuickAction"]
}) {
    const user = useUser()
    const quickActions = props.quickActions
    const orderedQuickActions = useMemo(() => {
        return _.orderBy(
            quickActions,
            [(qa) => qa.resource_type, (qa) => qa.title, (qa) => qa.question],
            ["asc", "asc", "asc"]
        )
    }, [quickActions])

    return (
        <div className="border border-gray-300 rounded-lg w-full overflow-auto text-sm">
            <table className="w-full">
                <thead>
                    <tr className="h-16">
                        <th className="text-left px-4 py-2 w-1/5">Title</th>
                        <th className="text-left px-4 py-2 w-1/3">
                            Quick Action Prompt
                        </th>
                        <th className="p-2 min-w-min">Resource</th>
                        <th className="p-2">Owner</th>
                        <th className="p-2">Created</th>
                        <th className="p-2">Access</th>
                        <th className="p-2">Edit</th>
                        <th className="p-2">Delete</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 border-t border-gray-300">
                    {orderedQuickActions?.map((quickAction) => (
                        <tr key={quickAction._id}>
                            <td className="px-4 py-2">
                                <div className="bg-yellow-50 border-yellow-300 text-yellow-900 border font-semibold rounded-lg px-2 py-1 w-fit whitespace-nowra min-w-">
                                    {quickAction.title}
                                </div>
                            </td>
                            <td className="px-4 py-2">
                                <div className="bg-white rounded-lg py-1 px-2">
                                    {quickAction.question}
                                </div>
                            </td>
                            <td className="p-2">
                                <ResourceCell
                                    resourceType={quickAction.resource_type}
                                />
                            </td>
                            <td className="p-2 text-center">
                                {getOwnerName(user, quickAction)}
                            </td>
                            <td className="p-2 text-center">
                                {getCreatedDate(quickAction)}
                            </td>
                            <td className="p-2 text-center">
                                <AccessIcon
                                    accessLevel={quickAction.access_level}
                                />
                            </td>
                            <td className="p-2 text-center">
                                <EditCell
                                    user={user}
                                    quickAction={quickAction}
                                    editQuickAction={props.editQuickAction}
                                />
                            </td>
                            <td className="p-2 text-center">
                                <DeleteCell
                                    user={user}
                                    quickAction={quickAction}
                                    deleteQuickAction={props.deleteQuickAction}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function getOwnerName(user: IUser | undefined, quickAction: SavedQuickAction) {
    if (quickAction.access_level === AccessLevel.Glyphic) {
        return "Glyphic"
    }
    if (quickAction.user_id && quickAction.user_id === user?.id) {
        return "You"
    }
    return quickAction.user_name
}

function getCreatedDate(quickAction: SavedQuickAction) {
    if (quickAction.access_level === AccessLevel.Glyphic) {
        // Don't show creation date for default quick actions
        return ""
    }
    return getSimpleDate(quickAction.created_at_utc)
}

function ResourceCell(props: { resourceType: string }) {
    return (
        <div
            className="flex flex-row items-center justify-center gap-2 cursor-default"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={`This quick action is available to be run over ${props.resourceType}.`}
        >
            {props.resourceType === "calls" ? (
                <RecordingIcon />
            ) : (
                <FontAwesomeIcon icon={faLandmark} />
            )}
            <span>{_.capitalize(props.resourceType)}</span>
        </div>
    )
}

function AccessIcon(props: { accessLevel: AccessLevel }) {
    switch (props.accessLevel) {
        case AccessLevel.Glyphic:
        case AccessLevel.Organization:
            return (
                <FontAwesomeIcon
                    icon={faEarthAmericas}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Visible to everyone in your organization"
                />
            )

        case AccessLevel.Team:
            return (
                <FontAwesomeIcon
                    icon={faUsers}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Visible to everyone in your team"
                />
            )

        case AccessLevel.Personal:
            return (
                <FontAwesomeIcon
                    icon={faUser}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Visible only to you"
                />
            )
    }
}

function EditCell(props: {
    user?: IUser
    quickAction: SavedQuickAction
    editQuickAction: (
        id: string,
        newTitle: string,
        accessLevel: AccessLevel,
        newQuestion?: string
    ) => Promise<void>
}) {
    const [isEditing, setIsEditing] = useState(false)
    const canEdit = hasEditPermission(props.user, props.quickAction)

    return (
        <>
            <SecondaryButton
                disabled={!canEdit}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    !canEdit
                        ? "You do not have permission to edit this Quick Action."
                        : ""
                }
                onClick={() => setIsEditing(true)}
            >
                <FontAwesomeIcon icon={faPencil} />
            </SecondaryButton>
            <EditQuickActionModal
                isOpen={isEditing}
                title={props.quickAction.title || ""}
                question={props.quickAction.question}
                accessLevel={props.quickAction.access_level}
                onClose={() => setIsEditing(false)}
                saveQuickAction={async (title, question, accessLevel) => {
                    props.editQuickAction(
                        props.quickAction._id,
                        title,
                        question,
                        accessLevel
                    )
                    setIsEditing(false)
                }}
            />
        </>
    )
}

function DeleteCell(props: {
    user?: IUser
    quickAction: SavedQuickAction
    deleteQuickAction: (id: string) => Promise<void>
}) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const canEdit = hasEditPermission(props.user, props.quickAction)

    return (
        <>
            <SecondaryButton
                className={clsx(canEdit && "hover:text-red-500")}
                disabled={!canEdit}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    !canEdit
                        ? "You do not have permission to delete this Quick Action."
                        : ""
                }
                onClick={() => setShowConfirmationModal(true)}
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </SecondaryButton>
            <QuickActionDeleteModal
                isOpen={showConfirmationModal}
                setIsOpen={setShowConfirmationModal}
                deleteQuickAction={props.deleteQuickAction}
                quickAction={props.quickAction}
            />
        </>
    )
}

function SuggestedQuickActionsTable(props: {
    quickActions: SavedQuickAction[]
    createQuickAction: QuickActionsApiHook["createQuickAction"]
}) {
    const user = useUser()
    const quickActions = props.quickActions
    const suggestionsMap = QUESTION_SUGGESTIONS

    const suggestions: { text: string; resourceType: ResourceType }[] =
        useMemo(() => {
            const resourceTypes = [ResourceType.Calls, ResourceType.Companies]
            return resourceTypes.flatMap((resourceType) =>
                filterOutAlreadySavedSuggestions(
                    quickActions,
                    suggestionsMap[resourceType],
                    resourceType
                )
            )
        }, [quickActions, suggestionsMap])

    return (
        <div className="border border-gray-300 rounded-lg w-full overflow-auto text-sm">
            <table className="w-full">
                <thead>
                    <tr className="h-16">
                        <th className="text-left px-4 py-2 w-2/3">
                            Suggested Quick Action
                        </th>
                        <th className="p-2 min-w-min">Resource</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 border-t border-gray-300">
                    {suggestions?.map((suggestion) => (
                        <tr
                            key={`${suggestion.resourceType}_${suggestion.text}`}
                        >
                            <td className="px-4 py-2">
                                <div className="bg-blue-50 border-blue-300 text-blue-900 border font-semibold rounded-lg px-2 py-1 w-fit whitespace-nowra min-w-">
                                    {suggestion.text}
                                </div>
                            </td>
                            <td className="p-2">
                                <ResourceCell
                                    resourceType={suggestion.resourceType}
                                />
                            </td>
                            <td className="p-2 text-center">
                                <SaveCell
                                    user={user}
                                    suggestion={suggestion.text}
                                    resourceType={suggestion.resourceType}
                                    createQuickAction={props.createQuickAction}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function filterOutAlreadySavedSuggestions(
    quickActions: SavedQuickAction[],
    suggestions: string[],
    resourceType: ResourceType
): { text: string; resourceType: ResourceType }[] {
    const fuseOptions = {
        threshold: 0.3,
    }

    // Get saved quick actions for this resource type
    const savedQuickActions = quickActions
        .filter((qa) => qa.resource_type === resourceType)
        .map((qa) => qa.question)

    const fuse = new Fuse(savedQuickActions, fuseOptions)

    // Fuzzy filter out suggestions that are already saved
    return suggestions
        .filter((suggestion) => {
            const result = fuse.search(suggestion)
            return result.length === 0 // If no similar saved string is found, keep the suggestion
        })
        .map((suggestion) => ({
            text: suggestion,
            resourceType: resourceType,
        }))
}

function SaveCell(props: {
    user?: IUser
    suggestion: string
    resourceType: ResourceType
    createQuickAction: QuickActionsApiHook["createQuickAction"]
}) {
    return (
        <SecondaryButton
            className="space-x-2"
            onClick={() =>
                props.createQuickAction(
                    props.suggestion,
                    AccessLevel.Personal,
                    props.suggestion,
                    props.resourceType
                )
            }
        >
            <FontAwesomeIcon icon={faBookmark} />
            <span>Add to library</span>
        </SecondaryButton>
    )
}
