import { useCallback, useEffect, useState } from "react"
import { useNotification } from "../../../providers/NotificationProvider"
import { AccessLevel, SavedQuickAction } from "../types/QuickActionTypes"
import { ResourceType } from "../types/ResourceTypes"
import axios from "axios"
import { NotificationType } from "../../common/Notifcations"
import { getHeapInstance } from "../../../utils/heap"

export interface QuickActionsApiHook {
    quickActions: SavedQuickAction[]
    createQuickAction: (
        title: string,
        accessLevel: AccessLevel,
        question: string,
        resourceType?: ResourceType
    ) => Promise<void>
    deleteQuickAction: (id: string) => Promise<void>
    editQuickAction: (
        id: string,
        newTitle: string,
        accessLevel: AccessLevel,
        newQuestion?: string
    ) => Promise<void>
}

export const useQuickActionsApi = (
    resourceType?: ResourceType
): QuickActionsApiHook => {
    const { addNotification } = useNotification()

    const [quickActions, setQuickActions] = useState<SavedQuickAction[]>([])

    function getQuickActionsUrl(resourceType?: ResourceType) {
        return (
            `${process.env.REACT_APP_API_DOMAIN}/templates/questions/` +
            (resourceType ? `${resourceType}/` : "")
        )
    }

    const fetchQuickActions = useCallback(async () => {
        try {
            const url = getQuickActionsUrl(resourceType)
            const response = await axios.get(url)
            const savedQuickActions = response.data as SavedQuickAction[]
            savedQuickActions.sort((a, b) => {
                const actionA = a.title || a.question
                const actionB = b.title || b.question
                return actionA.localeCompare(actionB, undefined, {
                    numeric: true,
                })
            })
            setQuickActions(savedQuickActions)
        } catch (error) {
            console.info(error)
        }
    }, [resourceType])

    const createQuickAction = useCallback(
        async (
            title: string,
            accessLevel: AccessLevel,
            question: string,
            resourceTypeArg?: ResourceType
        ): Promise<void> => {
            const actionResourceType = resourceType || resourceTypeArg
            console.assert(
                actionResourceType,
                "Cannot create quick action as resource type is not defined"
            )
            if (
                quickActions.some(
                    (t) => t.question.toLowerCase() === question.toLowerCase()
                )
            ) {
                addNotification(
                    "Action already saved!",
                    `You can now easily access this action on all ${actionResourceType}!`,
                    NotificationType.Success
                )
                return
            }
            try {
                const url = getQuickActionsUrl(actionResourceType)
                await axios.post(url, {
                    question: question.trim(),
                    title: title.trim(),
                    access_level: accessLevel,
                })
                getHeapInstance()?.track("askglyphic-quickaction-created", {
                    title,
                    question,
                    actionResourceType,
                })
                fetchQuickActions()
                addNotification(
                    "Action saved!",
                    `You can now easily access this action on all ${actionResourceType}!`,
                    NotificationType.Success
                )
            } catch (error) {
                console.error(error)
            }
        },
        [fetchQuickActions, addNotification, resourceType, quickActions]
    )

    const deleteQuickAction = useCallback(
        async (templateId: string): Promise<void> => {
            try {
                await axios.delete(
                    `${process.env.REACT_APP_API_DOMAIN}/templates/questions/${templateId}`
                )
                getHeapInstance()?.track("askglyphic-quickaction-deleted", {
                    quickActionId: templateId,
                })
                fetchQuickActions()
                addNotification(
                    "Removed quick action!",
                    `You will no longer see this action in the suggestions.`,
                    NotificationType.Success
                )
            } catch (error) {
                console.error(error)
            }
        },
        [fetchQuickActions, addNotification]
    )

    const editQuickAction = useCallback(
        async (
            id: string,
            newTitle: string,
            newAccessLevel: AccessLevel,
            newQuestion?: string
        ): Promise<void> => {
            try {
                await axios.patch(
                    `${process.env.REACT_APP_API_DOMAIN}/templates/questions/${id}`,
                    {
                        title: newTitle,
                        question: newQuestion,
                        access_level: newAccessLevel,
                    }
                )
                getHeapInstance()?.track("askglyphic-quickaction-edited", {
                    quickActionId: id,
                    newTitle,
                    newQuestion,
                })
                fetchQuickActions()
                addNotification("Action updated!", "", NotificationType.Success)
            } catch (error) {
                console.error(error)
            }
        },
        [fetchQuickActions, addNotification]
    )
    useEffect(() => {
        fetchQuickActions()
    }, [fetchQuickActions])

    return {
        quickActions: quickActions,
        createQuickAction: createQuickAction,
        deleteQuickAction: deleteQuickAction,
        editQuickAction: editQuickAction,
    }
}
