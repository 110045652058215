import { BotNameSetting } from "./BotNameSettings"
import { Card } from "./Card"
import { InternalDomains, RecordingDisallowedDomains } from "./DomainSettings"
import { ToggleSetting } from "./ToggleSetting"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import {
    DefaultUserRecordingSettings,
    OrgSettingsRequest,
    OrgSettingsResponse,
    RecordingOption,
} from "../../types/Settings"
import { getOrgSettings } from "../../api/Settings"
import axios from "axios"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useCallback, useState } from "react"
import { AnimatedLockIcon } from "../call-page/TogglePrivacyButton"
import { DangerousButton, SecondaryButton } from "../common/Buttons"
import { Modal } from "../common/Modal"
import { getHeapInstance } from "../../utils/heap"
import { LoadingPulse } from "../common/LoadingPulse"
import LoadingMessage from "../common/LoadingMessage"

export function OrgRecordingPreferences(props: { orgName?: string }) {
    const organizationName = props.orgName || "Organization"

    return (
        <Card
            title={`${organizationName}'s recording preferences`}
            label="Configure how Glyphic records your organization's calls."
        >
            <InternalRecordingPreference />
            <BotNameSetting orgName={props.orgName} />
            <InternalDomains />
            <RecordingDisallowedDomains />
        </Card>
    )
}

function InternalRecordingPreference() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()
    const { data } = useQuery<OrgSettingsResponse>({
        queryKey: ["organization/settings"],
        queryFn: getOrgSettings,
    })

    const { mutate, isPending } = useMutation({
        mutationFn: async (settings: OrgSettingsRequest) =>
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings`,
                settings
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["organization/settings"],
            })
            addNotification(
                "Successfully saved recording preferences.",
                "",
                NotificationType.Success
            )
        },
        onError: () => {
            addNotification(
                "Failed to save recording preferences.",
                "",
                NotificationType.Error
            )
        },
    })

    const setOrgRecordingPreferences = useCallback(
        async (newValue: DefaultUserRecordingSettings) => {
            if (!data) return

            const newSettings: OrgSettingsRequest = {
                default_recording_settings: newValue,
            }

            await mutate(newSettings)
        },
        [data, mutate]
    )

    function setDefaultInternalCallsToPrivate(checked: boolean) {
        mutate({ default_internal_calls_to_private: checked })
    }

    const defaultRecordingSettings = data?.default_recording_settings
    if (!defaultRecordingSettings) return null

    return (
        <div className="flex flex-col gap-1 w-full">
            <div className="flex flex-col w-full justify-between gap-2">
                <h3 className="text-md font-semibold w-fit">
                    Set default recording preferences for your organization
                </h3>
                <h3 className="text-md w-fit text-gray-600">
                    These are the default values any new user will start with
                    when they connect their calendar. Lock the changes to
                    enforce them across all users. A user can always manually
                    invite the copilot to a call to record it.
                </h3>
            </div>
            <RecordingOptionToggle
                name="Record external meetings"
                description="Automatically record meetings where there is at least one external attendee."
                loading={isPending}
                value={defaultRecordingSettings.record_external}
                onChange={async (newValue: RecordingOption) =>
                    setOrgRecordingPreferences({
                        ...defaultRecordingSettings,
                        record_external: newValue,
                    })
                }
            />
            <RecordingOptionToggle
                name="Record internal meetings"
                description="Automatically record meetings without any external attendees."
                loading={isPending}
                value={defaultRecordingSettings.record_internal}
                onChange={async (newValue: RecordingOption) =>
                    setOrgRecordingPreferences({
                        ...defaultRecordingSettings,
                        record_internal: newValue,
                    })
                }
            />
            <RecordingOptionToggle
                name="Only record meetings that have been accepted"
                description="Meetings that are tentative, declined, or not responded to will not be recorded."
                loading={isPending}
                value={defaultRecordingSettings.record_confirmed}
                onChange={async (newValue: RecordingOption) =>
                    setOrgRecordingPreferences({
                        ...defaultRecordingSettings,
                        record_confirmed: newValue,
                    })
                }
            />
            <RecordingOptionToggle
                name="Only record meetings where the individual is the host"
                description="Meetings they are invited to but not hosting will not be recorded."
                loading={isPending}
                value={defaultRecordingSettings.record_host}
                onChange={async (newValue: RecordingOption) =>
                    setOrgRecordingPreferences({
                        ...defaultRecordingSettings,
                        record_host: newValue,
                    })
                }
            />
            <div className="p-2 border-t border-gray-200">
                <ToggleSetting
                    name="Default internal meetings to private"
                    description="When enabled, all call recordings with only internal participants will be marked as private by default."
                    checked={data?.default_internal_calls_to_private ?? false}
                    disabled={false}
                    loading={isPending}
                    onChange={setDefaultInternalCallsToPrivate}
                />
            </div>
        </div>
    )
}

function RecordingOptionToggle(props: {
    name: string
    description: string
    value: RecordingOption
    onChange: (value: RecordingOption) => Promise<void>
    loading: boolean
}) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const lockString = props.value.allow_updating ? "Disable" : "Allow"

    if (props.loading) {
        return <LoadingPulse rows={1} height="h-16" />
    }

    return (
        <div className="flex flex-row p-2 justify-between gap-4 w-full items-center hover:bg-gray-50 rounded-md">
            <ToggleSetting
                name={props.name}
                // We only allow editing the option if the setting is not locked
                disabled={!props.value.allow_updating}
                disabledMessage="Please allow edits to change this setting."
                description={props.description}
                checked={props.value.default_value}
                onChange={(checked) =>
                    props.onChange({
                        ...props.value,
                        default_value: checked,
                    })
                }
                loading={props.loading}
            />
            <SecondaryButton
                onClick={() => {
                    getHeapInstance()?.track(
                        "settings/lock-recording-clicked",
                        {
                            name: props.name,
                        }
                    )
                    props.value.allow_updating
                        ? setShowConfirmationModal(true)
                        : props.onChange({
                              ...props.value,
                              // It's safe to enable updating
                              allow_updating: true,
                          })
                }}
                disabled={showConfirmationModal}
                className={
                    "flex flex-row gap-1 whitespace-nowrap items-center text-sm group text-gray-600 hover:text-gray-800"
                }
            >
                <span className="hidden md:block">{`${lockString} edits`}</span>
                <AnimatedLockIcon isPrivate={!props.value.allow_updating} />
            </SecondaryButton>
            <LockConfirmationModal
                name={props.name}
                value={props.value}
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                onChange={props.onChange}
            />
        </div>
    )
}

function LockConfirmationModal(props: {
    name: string
    value: RecordingOption
    showConfirmationModal: boolean
    setShowConfirmationModal: (value: boolean) => void
    onChange: (value: RecordingOption) => Promise<void>
}) {
    const [isPending, setIsPending] = useState(false)

    return (
        <Modal
            isOpen={props.showConfirmationModal}
            onClose={() => props.setShowConfirmationModal(false)}
        >
            <div className="p-3">
                <div className="font-semibold text-lg p-2 mb-8 mr-8 max-w-lg">
                    {props.value.default_value ? "Enable" : "Disable"}
                    {` "${props.name.toLocaleLowerCase()}"`} for all users in
                    your organization.
                </div>
                <div className="flex justify-end mt-8 gap-4">
                    <SecondaryButton
                        onClick={() => props.setShowConfirmationModal(false)}
                    >
                        Cancel
                    </SecondaryButton>
                    <DangerousButton
                        onClick={async () => {
                            setIsPending(true)
                            try {
                                await props.onChange({
                                    ...props.value,
                                    // We're locking or wouldn't be asking
                                    // for confirmation
                                    allow_updating: false,
                                })
                            } finally {
                                setIsPending(false)
                                props.setShowConfirmationModal(false)
                            }
                        }}
                    >
                        {isPending ? (
                            <LoadingMessage textColor="gray-200" />
                        ) : (
                            "Confirm"
                        )}
                    </DangerousButton>
                </div>
            </div>
        </Modal>
    )
}
