import clsx from "clsx"
import { getSimpleDate } from "../../../utils/datetime"
import { ICRMDeal } from "../../../types/Deal"
import { PlacesType, Tooltip } from "react-tooltip"
import { ColoredLabelSquare } from "../common/ColoredLabelSquare"
import tailwindColors from "tailwindcss/colors"
import { Link } from "react-router-dom"
import { LoadingPulse } from "../../common/LoadingPulse"
import { CompanyLogo } from "../../company-view/CompanyLogo"
import _ from "lodash"
import { useState, useEffect } from "react"

export function DealsTooltip({
    tooltipId,
    deals,
    pendingRowCount,
    place,
    isOpen,
}: {
    tooltipId: string
    deals: ICRMDeal[]
    pendingRowCount: number
    place: PlacesType
    isOpen?: boolean
}) {
    const maxTooltipDealsToShow = 10
    const delayShow = 100 // Avoid flickering when scrolling past many ChartBars
    const delayHide = 200 // Delay hide to ease clicking into it

    // If isOpen is being used, we need to manually debounce it to get the delayShow/delayHide behavior
    const [debouncedIsOpen, setDebouncedIsOpen] = useState<boolean | undefined>(
        isOpen
    )
    useEffect(() => {
        if (isOpen === undefined) return

        const timer = setTimeout(
            () => {
                setDebouncedIsOpen(isOpen)
            },
            isOpen ? delayShow : delayHide
        )

        return () => clearTimeout(timer)
    }, [isOpen, delayShow, delayHide])

    return (
        <Tooltip
            id={tooltipId}
            place={place}
            className="z-50 !opacity-100"
            clickable={true}
            delayShow={delayShow}
            delayHide={delayHide}
            isOpen={debouncedIsOpen}
            style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
                padding: "8px",
            }}
        >
            {pendingRowCount ? (
                <LoadingPulse
                    rows={Math.min(pendingRowCount, maxTooltipDealsToShow)}
                    height="h-10"
                    width="w-52"
                />
            ) : (
                <DealList
                    deals={deals}
                    maxTooltipDealsToShow={maxTooltipDealsToShow}
                />
            )}
        </Tooltip>
    )
}

function DealList({
    deals,
    maxTooltipDealsToShow,
}: {
    deals: ICRMDeal[]
    maxTooltipDealsToShow: number
}) {
    if (!deals.length) {
        return null
    }

    const sortedDeals = _.orderBy(
        deals,
        [(deal) => (deal.amount === null ? -1 : deal.amount)],
        ["desc"]
    )
    const dealsToShow = sortedDeals.slice(0, maxTooltipDealsToShow)

    return (
        <div>
            <ul className=" text-gray-600 text-sm divide-y-[1px] divide-gray-200">
                {dealsToShow.map((deal) => (
                    // Wrap each item in non-rounded div so the divide-y is not rounded
                    <div>
                        <DealListItem key={deal.id} deal={deal} />
                    </div>
                ))}
                {sortedDeals.length > maxTooltipDealsToShow && (
                    <li className="pt-2">
                        Showing {maxTooltipDealsToShow} of {sortedDeals.length}{" "}
                        deals
                    </li>
                )}
            </ul>
        </div>
    )
}

function DealListItem({ deal }: { deal: ICRMDeal }) {
    const companyDomain = deal.company?.domain
    const isClickable = !!companyDomain
    const url = isClickable ? `/companies/${companyDomain}` : "#" // "#" prevents the <Link> from being clickable

    return (
        <Link
            key={deal.id}
            to={url}
            className={clsx(
                "flex items-center gap-2 rounded-md p-2 w-full",
                isClickable
                    ? "cursor-pointer hover:bg-gray-200 transition-colors"
                    : "cursor-default"
            )}
        >
            <CompanyLogo image_url={deal.company?.image_url ?? null} />
            <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row justify-between w-full gap-2 items-center">
                    <span className="font-semibold text-black truncate max-w-[200px]">
                        {deal.name}
                    </span>
                    <ColoredLabelSquare
                        color={
                            deal.is_won
                                ? tailwindColors.green[500]
                                : tailwindColors.red[500]
                        }
                        isSelected={false}
                    />
                </div>
                <div className="space-x-2 divide-x-[1px] divide-gray-400">
                    <span>{getSimpleDate(deal.close_date!)}</span>
                    {deal.owner?.name && (
                        <span className="pl-1">{deal.owner.name}</span>
                    )}
                    {deal.amount && (
                        <span className="pl-1">
                            {deal.amount.toLocaleString()}
                        </span>
                    )}
                </div>
            </div>
        </Link>
    )
}
