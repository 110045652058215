import { useCallback, useMemo } from "react"
import Creatable from "react-select/creatable"
import { DEFAULT_GROUP_DISPLAY_NAME } from "./insightsCommon"

export function InsightGroupSelect(props: {
    groups: string[]
    group: string | undefined
    setGroup: (group: string) => void
}) {
    const groups = props.groups
    const options = useMemo(
        () => [
            { value: "", label: DEFAULT_GROUP_DISPLAY_NAME },
            ...groups.map((group) => ({
                value: group,
                label: group,
            })),
        ],
        [groups]
    )

    const setGroup = props.setGroup
    const handleChange = useCallback(
        (
            option: {
                value: string | undefined
                label: string | undefined
            } | null
        ) => {
            if (option && setGroup) {
                setGroup(option.value || "")
            }
        },
        [setGroup]
    )

    return (
        <Creatable
            className="w-full"
            options={options}
            placeholder="Choose a group or type to create new..."
            onChange={handleChange}
            menuPlacement="top"
            aria-label="Select group"
            isSearchable
            isClearable
            formatCreateLabel={(inputValue) => `Create group "${inputValue}"`}
        />
    )
}
